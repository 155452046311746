import { APP_BASE_PATH } from '@variables';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const ROOT_STACK: AppNavigationData = {
  path: `${APP_BASE_PATH}`,
  state: undefined,
};

interface AppNavigationData {
  path?: string;
  state?: any;
}

interface AppNavigationStoreProps {
  historyState: any;
  stack: AppNavigationData[];
  update: (data: AppNavigationData) => void;
  push: (data: AppNavigationData) => void;
  pop: () => AppNavigationData;
  clear: () => void;
  setHistoryState: (idx: number) => void;
}

export const useAppNavigationStore = create(
  persist<AppNavigationStoreProps>(
    (set, get) => ({
      historyState: {},
      setHistoryState: (state: any) => {
        set({ historyState: state });
      },
      stack: [],
      push: (data: AppNavigationData) => {
        set((state) => {
          const stack = [...state.stack];
          const last = stack[stack.length - 1];
          if (!last || last.path !== data.path) {
            stack.push(data);
          }
          const newState = { ...state, stack: stack };
          return newState;
        });
      },
      update: (data: AppNavigationData) => {
        const stack = [...get().stack];
        if (stack.length > 0) {
          stack.splice(-1);
          stack.push(data);
          set({ stack: stack });
        }
      },
      pop: () => {
        const stack = [...get().stack];
        const data = stack.pop() || ROOT_STACK;
        set({ stack: stack });
        return data;
      },
      clear: () => {
        set({ stack: [] });
      },
    }),
    {
      name: 'igogo-app-navigation',
    },
  ),
);
