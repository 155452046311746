import React from 'react';

const DEFAULT_THEME = { backgroundColor: '#FF3D8F', color: 'white', border: 'none', fontWeight: 700 };
const THEME = {
  primary: { backgroundColor: '#FF3D8F', color: 'white', border: 'none', fontWeight: 700 },
  secondary: { backgroundColor: 'white', color: '#424242', border: '1px solid #E8EAED', fontWeight: 600 },
};

interface ClassInvoiceButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: any;
  theme?: 'primary' | 'secondary';
}

export const ClassInvoiceButton: React.FC<ClassInvoiceButtonProps> = (props) => {
  const { color, backgroundColor, border, fontWeight } = (props.theme && THEME[props.theme]) || DEFAULT_THEME;

  return (
    <button
      className="link-button"
      style={{
        width: '100%',
        height: '44px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '12px',
        borderRadius: '8px',
        border: border,
        color: color,
        textAlign: 'center',
        fontFamily: 'Pretendard',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: fontWeight,
        lineHeight: '20px',
        backgroundColor: backgroundColor,
      }}
      onClick={props.onClick}>
      {props.children}
    </button>
  );
};
