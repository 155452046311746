import React from 'react';
import './NotFound.scss';

export const RedirectToHome: React.FC = () => {
  window.location.href = `/`;

  return <div></div>;
};

export default RedirectToHome;
