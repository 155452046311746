import { PaymentStatement } from '@models/purchase';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type PaymentStatus = 'ready' | 'in_progress' | 'done' | 'error' | 'cancel';

interface PaymenttoreProps {
  status: PaymentStatus;
  setStatus: (status: PaymentStatus) => void;
  paymentStatement?: PaymentStatement;
  setPaymentStatement: (paymentStatement?: PaymentStatement) => void;
  clear: () => void;
}

export const usePaymentStore = create(
  persist<PaymenttoreProps>(
    (set) => ({
      status: 'ready',
      setStatus: (status: PaymentStatus) => {
        set({ status });
      },
      paymentStatement: undefined,
      setPaymentStatement: (paymentStatement?: PaymentStatement) => {
        set({ paymentStatement });
      },
      clear: () => {
        set({ status: 'ready', paymentStatement: undefined });
      },
    }),
    { name: 'igogo-app-payment-statement' },
  ),
);
