import { create } from 'zustand';

interface AppInstallModalStore {
  show: boolean;
  setShow: (show: boolean) => void;
}

export const useAppInstallModal = create<AppInstallModalStore>((set) => ({
  show: false,
  setShow: (show: boolean) => {
    set({ show });
  },
}));
