import { getUniqueKeyFromUUID } from '@utils/string';
import moment from 'moment';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AppUserActionData {
  actionType?: string;
  from?: string;
  to?: string;
  state?: any;
}

interface AppUserSessionStoreProps {
  sessionId: string;
  updatedAt: Date;
  step: number;
  actionData: AppUserActionData;
  getSessionId: () => string;
  setActionData: (data: AppUserActionData) => void;
}

export const useAppUserSessionStore = create(
  persist<AppUserSessionStoreProps>(
    (set, get) => ({
      sessionId: getUniqueKeyFromUUID(),
      step: 0,
      updatedAt: new Date(),
      actionData: {},
      setActionData: (data) => {
        set((state) => {
          return {
            ...state,
            actionData: data,
            step: state.step + 1,
          };
        });
      },
      getSessionId: () => {
        const currentDate = new Date();
        const updatedDate = get().updatedAt;
        let newSessionId = get().sessionId;
        if (moment(updatedDate).add(30, 'minutes') < moment(currentDate)) {
          newSessionId = getUniqueKeyFromUUID();
          set({ sessionId: newSessionId, updatedAt: currentDate, step: 0 });
        }
        return newSessionId;
      },
    }),
    {
      name: 'igogo-app-session',
    },
  ),
);
