import { create } from 'zustand';

interface AppBottomSheetStoreOptions {
  height: number;
  onClose?: () => void;
  elements?: any;
}

interface AppBottomSheetStoreProps {
  show: boolean;
  options: AppBottomSheetStoreOptions;
  open: (options: AppBottomSheetStoreOptions) => void;
  close: () => boolean;
}

export const useAppBottomSheetStore = create<AppBottomSheetStoreProps>((set, get) => ({
  show: false,
  options: { height: 0 },
  open: (options: AppBottomSheetStoreOptions) => {
    console.trace();
    set({ options: { ...options, height: options.height || 400 }, show: true });
  },
  close: () => {
    const isCloseExecute = !!get().show;
    set({ show: false });
    return isCloseExecute;
  },
}));
