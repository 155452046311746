import React from 'react';
import { MOBILE_CONFIRM_MODAL_WIDTH } from '@consts/ui';
import { Modal } from 'antd';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';

export const OkCancelDialog: React.FC = () => {
  const { show, data, close } = useOkCancelDialog();
  return (
    <Modal
      width={MOBILE_CONFIRM_MODAL_WIDTH}
      centered
      open={show}
      closable={false}
      onCancel={() => {
        close();
      }}
      footer={null}>
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '12px',
        }}>
        {data?.customTitle ? (
          <div>{data.customTitle}</div>
        ) : (
          <div
            style={{
              whiteSpace: 'pre-line',
              color: 'var(--Neutral-100, #242424)',
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '24px',
            }}>
            {data?.title}
          </div>
        )}

        <div
          style={{
            marginTop: '8px',
            marginBottom: '24px',
            color: 'var(--Neutral-80, #66686B)',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            whiteSpace: 'pre-line',
          }}>
          {data?.content}
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {data?.type !== 'ok' && (
            <div
              style={{ flex: 1 }}
              onClick={() => {
                data && data.onCancel && data.onCancel();
                close();
              }}>
              <div
                style={{
                  height: '48px',
                  backgroundColor: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderStyle: 'solid',
                  borderRadius: '8px',
                  borderWidth: '1px',
                  borderColor: '#dadcdf',
                  marginRight: '8px',
                  cursor: 'pointer',
                }}>
                <div style={{ color: '#808387', fontSize: '16px', fontWeight: 'bold' }}>
                  {data?.cancelText || '취소'}
                </div>
              </div>
            </div>
          )}

          <div
            style={{ flex: 1 }}
            onClick={() => {
              data && data.onConfirm && data.onConfirm();
              close();
            }}>
            <div
              style={{
                height: '48px',
                backgroundColor: data?.confirmColor ? data?.confirmColor : '#0c8eff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '8px',
                cursor: 'pointer',
              }}>
              <div style={{ color: '#FFFFFF', fontSize: '16px', fontWeight: 'bold' }}>
                {data?.confirmText || '확인'}
              </div>
            </div>
          </div>

          {data?.type === 'ok_all_cancel' && (
            <div
              style={{ flex: 1 }}
              onClick={() => {
                data && data.onConfirmAll && data.onConfirmAll();
                close();
              }}>
              <div
                style={{
                  height: '48px',
                  backgroundColor: data?.confirmColor ? data?.confirmColor : '#0c8eff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  marginLeft: '8px',
                }}>
                <div style={{ color: '#FFFFFF', fontSize: '16px', fontWeight: 'bold' }}>
                  {data?.confirmAllText || '확인'}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OkCancelDialog;
