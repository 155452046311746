import { PagenatedResponse } from './common';

export interface ReviewContent {
  _id: string;
  content: string;
  profileName: string;
  profileImageUrl?: string;
  saleType: string;
  productId: string;
  productName: string;
  productImageUrl: string;
  children: Children[];
  age: string;
  avg: number;
  createdAt: Date;
  reviewContents: ReviewRate;
}

export interface ReviewRate {
  rate1: number;
  rate2: number;
  rate3: number;
  rate4: number;
}

export interface ReviewItem extends ReviewRate {
  content?: string;
}

export interface UpdateReviewBody {
  classInvoiceId: string;
  classProductId: string;
  rate1?: number;
  rate2?: number;
  rate3?: number;
  rate4?: number;
  content: string;
}

export interface CreateReviewBody {
  classInvoiceId: string;
  classProductId: string;
  parentUserId: string;
  rate1?: number;
  rate2?: number;
  rate3?: number;
  rate4?: number;
  content: string;
}

export enum ReviewRateType {
  SOCIABILITY = '친화력',
  PROFESSIONALISM = '전문성',
  READINESS = '준비성',
  PUNCTUALITY = '시간준수',
}

export interface Children {
  name: string;
  birthday: string;
  gender: string;
}

export interface ReviewContainer extends PagenatedResponse<ReviewContent> {
  rateSummary?: { avg: number; rate1: number; rate2: number; rate3: number; rate4: number };
}
