import React from 'react';
import './TermsPage.scss';

export const TermsContent: React.FC = () => {
  return (
    <div className="description">
      <p style={{ textAlign: 'center' }}>
        <span style={{ fontSize: '24px' }}>
          <b>서비스 이용약관</b>
        </span>
      </p>

      <p style={{ textAlign: 'center' }}> </p>

      <p style={{ textAlign: 'right' }}>
        <span style={{ fontSize: '12px' }}>최종 수정일 : 2021년 1월 21일</span>
      </p>

      <p>
        <span style={{ fontSize: '18px' }}>
          <b>제1장 총칙</b>
        </span>
      </p>

      <p>
        <b>제1조 (목적)</b>
      </p>

      <p>
        이 약관은 (주)아이들랩(이하 당사)이 제공하는 클래스 연결 서비스를 이용함에 있어 당사와 이용자의 권리, 의무 및
        책임사항을 규정합니다. 이를 통하여 당사와 이용자는 알아야 할 사항을 숙지, 상호 신뢰의 증진을 목적으로 합니다.
      </p>

      <p>
        <b>제2조 (약관의 효력 및 변경)</b>
      </p>

      <p>
        가. 이 약관은 이용자가 아이고고 사이트에 회원으로 등록, 조회 등 서비스를 이용하는 순간부터 효력이 발생됩니다.
      </p>

      <p>
        나. 당사에서는 서비스 이용 수수료 등을 포함하여 각종 약관을 어느 시기에나 변경할 권리를 갖고 있으며, 사전 고지
        없이 이 약관들의 내용을 변경할 수 있습니다. 변경된 약관은 홈페이지 공지, 회원가입 시 공지 또는 전자우편 등의
        기타 방법으로 공지함으로써 효력이 발생됩니다. 당사는 또한 “마지막 수정일”의 날짜를 약관의 가장 상단에 나타낼
        것입니다. 이는 서비스 이용약관뿐만 아니라, 결제서비스약관, 환불규정, 개인정보처리방침 약관 모두가 해당됩니다.
      </p>

      <p>
        다. 기존 회원들은 당사가 변경된 약관의 수정사항을 홈페이지, 어플리케이션 또는 이메일 등의 방법을 통해 공지한
        경우, 웹사이트, 어플리케이션 또는 서비스를 지속적으로 이용할 경우에 변경 사항에 대해 동의 한 것으로 간주합니다.
        만일 수정 약관에 대해 동의하지 않는 경우에는 사이트와 서비스 이용을 즉시 중단 하거나 탈퇴함으로써 약관의 내용을
        거부할 수 있습니다.
      </p>

      <p>
        <b>제3조 (약관 외 사항의 처리)</b>
      </p>

      <p>
        본 약관과 개인정보취급방침에 명시되지 않은 사항에 대해서는 관련 법령 및 회사가 정한 서비스 이용 안내에 따릅니다.
      </p>

      <p>
        <b>제4조 (용어의 정리)</b>
      </p>

      <p>이 약관에서 사용하는 주요한 용어의 정의는 다음과 같습니다.</p>

      <p>가. 부모님 : 클래스를 찾기 위해 당사의 약관에 동의하고 회원가입 양식에 따라 등록한 자.</p>

      <p>나. 아이 : 당사의 약관에 동의하고 회원가입을 진행한 부모님의 자녀</p>

      <p>다. 튜터 : 클래스를 수강할 부모 및 아이를 찾기 위해, 당사의 약관에 동의하고 튜터 양식에 따라 등록한 자.</p>

      <p>
        라. 서비스 : 아이고고 사이트와 모바일 앱 아이고고에서 판매 가능한 상품으로 유형의 제품 혹은 무형의 서비스를
        말합니다.
      </p>

      <p>
        마. 회원 : 본 약관을 승인하고 회사와 서비스 이용계약을 체결한 자를 말하며, 회원은 가입 형태에 따라 개인회원과
        기업회원으로, 서비스 이용목적에 따라 부모님과 튜터로 각 구분되며 상호간의(부모님, 튜터) 구별은 회사가 정하는
        바에 따라 전환할 수 있습니다. 부모님과 튜터 모두에게 이 약관이 적용됩니다.
      </p>

      <p>바. 클래스 : 튜터와 부모님 및 아이가 진행하는 오프라인/온라인 클래스를 뜻합니다.</p>

      <p>
        <b>사. 정규 클래스 : 2회 이상의 커리큘럼으로 진행되는 클래스의 단위.</b>
      </p>

      <p>
        <b>아. 원데이 클래스 : 1회의 커리큘럼으로 진행되는 클래스의 단위.</b>
      </p>

      <p>
        <b>제5조 (중개서비스의 성질과 목적)</b>
      </p>

      <p>
        중개서비스는 회사가 회원 각자의 자기결정에 의하여 회원 상호간에 클래스의 거래가 이루어질 수 있도록 사이버
        거래장소(marketplace)를 온라인으로 제공하는 것이며, 아이고고 안전결제서비스는 회원 상호간에 클래스의 매매에
        있어서 안전하고 편리하게 결제가 이루어질 수 있는 수단을 제공하는 것입니다. 회사는 단지 회원간 거래의 안전성 및
        신뢰성을 증진시키는 도구만을 제공합니다. 회원간에 성립된 거래와 관련된 책임은 거래당사자인 회원들 스스로가
        부담하여야 합니다.
      </p>

      <p>
        <span style={{ fontSize: '18px' }}>
          <b>제2장 회원정보의 보호</b>
        </span>
      </p>

      <p>
        <b>제6조 (회원정보의 수집과 보호)</b>
      </p>

      <p>이 약관에서 사용하는 주요한 용어의 정의는 다음과 같습니다.</p>

      <p>
        가. 회사는 이용계약을 위하여 회원이 제공한 정보 외에도 수집목적 또는 이용목적 등을 밝혀 회원으로부터 필요한
        정보를 수집·이용할 수 있습니다. 이 경우, 회사는 회원으로부터 정보수집·이용에 대한 동의를 받습니다. 회사는
        개인정보의 수집·이용·제공에 관한 회원의 동의 거절 시 제한되는 서비스를 구체적으로 명시합니다.
      </p>

      <p>
        나. 회사가 정보수집·이용을 위하여 회원의 동의를 받는 경우, 회사는 정보의 수집목적 및 이용목적, 제3자에 대한
        정보제공 관련사항(제공받는 자, 제공 목적, 제공 정보의 내용)을 미리 명시하거나 고지합니다. 회원은 정보제공에
        동의하더라도 언제든지 그 동의를 철회할 수 있습니다.
      </p>

      <p>
        다. 회원은 회사에게 정보를 사실대로 제공하여야 합니다. 회사는 회원이 이 약관 제8조 가항에 따라 제공한 정보의
        정확성을 확인하기 위하여 관련 법령이 허용하는 범위 내에서 증빙 자료의 제공을 요청할 수 있고, 회원이 정당한 사유
        없이 증빙자료를 제공하지 않는 경우 회사는 서비스의 이용을 제한하거나 이용계약을 해지할 수 있습니다.
      </p>

      <p>
        라. 회사는 계정, 성명 등 서비스화면을 통하여 공개되는 정보를 제외하고 회사가 수집한 회원정보를 정보수집 시에
        밝힌 수집목적, 이용목적, 원활한 서비스의 제공 및 제공 서비스의 확대 외의 용도로 사용하거나 제3자에게 제공하지
        않습니다. 회사는 정보수집 시에 밝힌 이용목적 이외에 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우,
        이용∙제공 단계에서 당해 회원에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는
        예외로 합니다.
      </p>

      <p>
        마. 회사의 개인정보 관리책임자 및 연락처는 아이고고 사이트에서 별도로 공개하는 개인정보취급방침에서 공지합니다.
      </p>

      <p>
        바. 회원은 언제든지 자신에 관한 정보를 열람할 수 있고, 회사 또는 정보관리책임자에게 잘못된 정보에 대한 정정을
        요청할 수 있습니다. 회사는 회원으로부터 정보정정의 요청이 있는 경우 그 오류를 정정하거나 정정하지 못하는 사유를
        회원에게 통지하는 등 필요한 조치를 취할 때까지 당해 정보를 이용하지 않습니다. 다만, 다른 법률에 따라 개인정보의
        제공을 요청 받은 경우에는 그러하지 아니합니다.
      </p>

      <p>
        사. 회사는 회원정보의 보호를 위하여 관리자를 최소한으로 한정하며, 회사의 고의 또는 과실로 인하여 회원정보가
        분실, 도난, 유출, 변조되거나 위법하게 제3자에게 제공된 경우에는 그로 인한 회원의 손해에 대하여 모든 책임을
        부담합니다.
      </p>

      <p>
        아. 이용계약이 종료된 경우, 회사는 당해 회원의 정보를 파기하는 것을 원칙으로 합니다. 다만, 아래의 경우에는
        회원정보를 보관합니다. 이 경우 회사는 보관하고 있는 회원정보를 그 보관의 목적으로만 이용합니다.
      </p>

      <p>
        1) 상법·전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우, 회사는
        관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
      </p>

      <p>
        2) 회원이 비방이나 허위사실 유포 등으로 타인이나 회사에 피해를 입힌 경우, 지적재산권 침해상품 판매 또는 기타
        인터넷 사기행위 등으로부터 회원과 회사를 보호하고 법적 절차에 따른 수사 협조를 위한 목적 등으로 회사는 이용계약
        종료 후 2개월간 물품거래내역이 존재하는 회원의 계정, 성명 또는 상호, 연락처, 그 외 제공받은 개인정보, 해지 및
        회원자격정지 관련정보 등 필요한 정보를 보관합니다.
      </p>

      <p>3) 기타 정보수집에 관한 동의를 받을 때 보유기간을 명시한 경우, 그 보유기간까지 회원정보를 보관합니다.</p>

      <p>
        자. 회사가 제3자에게 회원의 개인정보를 제공할 필요가 있는 경우, 회사는 실제 구매신청 시(부모님) 또는 클래스 등록
        시(튜터) 제공되는 개인정보 항목, 제공받는 자, 제공받는 자의 개인정보 이용목적 및 보유·이용기간 등을 명시하여
        회원의 동의를 받고, 개인정보의 수집·취급·관리 등을 위탁하는 경우에는 위탁 업무의 내용 및 수탁사의 명칭 등에 관한
        사항을 서면, 전자우편, 모사전송, 전화 또는 홈페이지를 통해 미리 회원에게 고지하여 동의를 받거나
        개인정보취급방침에 포함하여 아이고고 웹사이트(https://igogo.kr)에 공개합니다.
      </p>

      <p>
        차. 회사는 회원정보의 보호와 관리에 관한 개인정보취급방침을 회원과 회사의 서비스를 이용하고자 하는 자가 알 수
        있도록 아이고고 웹사이트(https://igogo.kr)에 공개합니다.
      </p>

      <p>
        <span style={{ fontSize: '18px' }}>
          <b>제3장 중개서비스의 이용</b>
        </span>
      </p>

      <p>
        <b>제7조 (중개서비스의 종류 및 이용)</b>
      </p>

      <p>
        가. 회사가 제공하는 중개서비스는 일반거래, 합의거래 등이 있습니다. 중개서비스의 종류와 내용은 회사의 정책에 따라
        추가, 변경될 수 있습니다.
      </p>

      <p>
        나. 회사는 중개서비스의 종류에 따라 각 매매의 특성, 절차 및 결제방법에 대한 사항을 서비스화면을 통하여
        공지합니다. 회원은 회사가 공지한 각 매매에 관한 사항을 사전에 충분히 이해하고 중개서비스를 이용하여야 합니다.
      </p>

      <p>
        다. 회원은 SNS 계정, 이메일(e-mail)과 비밀번호를 통하여 회사가 제공하는 중개서비스에 접속할 수 있고, 동 서비스를
        이용하여 클래스를 판매하거나 구매할 수 있습니다. 다만 각 카테고리 별로 회사가 지정하는 중개서비스에 대해서는,
        튜터가 될 수 있는 자격요건이 설정될 수 있습니다. 회사는 이러한 이용제한사항을 해당 서비스화면에서 공지합니다.
      </p>

      <p>
        라. 회원이 중개서비스를 통하여 판매 또는 구매를 하는 경우, 반드시 회사가 마련한 아이고고 안전결제서비스를 통하여
        거래를 완료하여야 합니다. 회사는 회원 간의 직거래로 인하여 발생한 문제에 대하여 책임지지 않습니다. 또한 직거래
        신고가 접수 될 경우 이용자는 서비스 이용에 제한을 받을 수 있습니다.
      </p>

      <p>
        <b>제8조 (중개서비스의 활용 방식과 제한 정책)</b>
      </p>

      <p>
        가. 회사는 중개서비스를 통한 거래의 안전성과 신뢰성을 위하여 이 약관에서 정한 바에 따라 회원자격을 정지하거나
        서비스의 이용 제한 및 기타 필요한 조치를 취할 수 있습니다. 회사가 회원자격을 정지하거나 판매/구매서비스의 이용
        제한 및 기타 필요한 조치를 취할 경우, 회사는 이 약관에 특별한 규정이 없는 한 사전에 회원에게 유선 또는
        메일(e-mail), 기타의 방법을 통하여 통보하며, 회원의 연락이 두절되거나 긴급을 요하는 것과 같이 부득이한 경우 선
        조치 후 사후 통보할 수 있습니다. 정지된 회원은 중개서비스를 이용할 수 없으며, 중개서비스의 이용이 제한된 회원은
        판매/구매관리 서비스를 포함한 모든 서비스를 이용할 수 없습니다.
      </p>

      <p>나. 회원자격의 정지와 중개서비스의 이용제한</p>

      <p>
        <b>1) 불법홍보 또는 회사 서버에 무리를 주는 행위로 인한 회원자격정지</b>
      </p>

      <p>
        회사는 타 사이트 등에서 아이고고 서비스를 이용한 불법적 홍보행위, 회사 서버에 무리를 주는 행위의 적발 시 이용
        제한을 진행할 수 있습니다. 아래 기준을 따르되, 위반 정도에 따라 회사는 기준보다 높은 제한을 할 수 있습니다.
      </p>

      <ul>
        <li>
          <b>적발에 따른 제한의 내용</b>
        </li>
      </ul>

      <p>적발 시 회원자격 즉시 정지 후 유선/대면 상담 진행하며, 결과에 따라 회원자격 상실 또는 유지 결정</p>

      <p>
        <b>2) 직거래 유도 행위</b>
      </p>

      <p>
        회사는 상호간의 신뢰도 증진 및 서비스의 품질 보장을 위해, 직거래유도행위에 대하여 그 적발횟수에 따라 아래와 같은
        제한을 부과합니다. 아래 기준을 따르되, 위반 정도에 따라 회사는 기준보다 높은 제한을 할 수 있습니다. 이용계약해지
        후 재가입하는 경우에 회원에게 이용계약해지 전 부과된 제한은 그대로 유지되며, 해지 시점으로부터 재가입까지의
        기간은 적발횟수 차감 기간에서 제외될 수 있습니다.
      </p>

      <ul>
        <li>
          <b>적발에 따른 제한의 내용</b>
        </li>
      </ul>

      <p>적발 시 회원자격 즉시 정지 후 유선/대면 상담 진행하며, 결과에 따라 회원자격 상실 또는 유지 결정</p>

      <p>
        <b>3) 부당한 구매행위, 불법카드거래 또는 매매부적합클래스의 등록행위</b>
      </p>

      <p>
        회사는 허위거래, 매매조작행위 등 매매진행을 방해하는 부당한 구매행위, 불법카드거래행위, 매매부적합클래스의
        등록행위, 불법통신과금서비스 이용행위에 대하여 회원자격을 정지하거나 중개서비스 이용을 제한할 수 있습니다. 다만,
        튜터의 경과실에 의해 회원자격의 정지 또는 중개서비스의 이용제한 사유가 발생하는 경우에는 회사는 1회 사전 경고를
        하여 튜터가 자진 시정을 할 수 있는 기회를 제공할 수 있습니다.
      </p>

      <p>
        <b>4) 장기휴면회원의 경우</b>
      </p>

      <p>
        회사는 튜터 회원이 60일 이상 로그인 하지 않은 경우 등록한 클래스를 중단처리 할 수 있고, 회원이 1년 이상 로그인을
        하지 않은 경우에는 ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’ 제 29조 2항에 따라 자동 탈퇴 및 개인정보를
        파기합니다. 단, 판매/구매이력이 있는 회원의 자동탈퇴 및 개인정보 보관, 파기는 개인정보취급방침에 따른 별도
        규정에 의하여 관리합니다.
      </p>

      <p>
        <b>5) 금지행위</b>
      </p>

      <p>① 허위 정보 입력 금지</p>

      <p>
        허위 정보 입력 금지란 클래스에 대한 허위 정보를 입력하는 행위를 말합니다. 이로 인해 부모님 및 아이의 피해나
        클레임이 발생할 경우, 회사는 판매제한, 클래스 등록 가능개수 제한 등의 페널티를 부여할 수 있습니다.
      </p>

      <p>② 이용정책 위반 시</p>

      <p>
        튜터와 부모님이 취소, 환불 절차 등 이용정책 위반 및 클래스 후 무응답 등 클래스에 상호간에 해가 되는 행동을
        끼쳤거나 이로 인한 클레임이 발생하였을 경우, 회사는 아래의 규정에 따라 조치를 할 수 있습니다.
      </p>

      <p>
        <b>(1) 부당 클래스 거부</b>
      </p>

      <p>
        부당 클래스 거부란 튜터가 아이고고 약관이 정하는 운영정책 기준 상의 합당한 이유없이 특정 회원의 클래스 수강
        신청을 일방적으로 거부하는 것을 뜻합니다. 단, 튜터의 거부 사유를 회사에게 통보하여 합리적 판단에 의해 클래스
        서비스 제공을 거부할 필요가 있다고 양측이 인정하는 경우는 예외로 합니다.
      </p>

      <p>- 1차 : 부당 클래스 거부에 대한 경고 및 개선요청</p>

      <p>- 2차 : 해당 클래스 등록 중지</p>

      <p>- 3차 : 전 클래스 등록 중지 및 신규 클래스 등록제한</p>

      <p>
        <b>(2) 임의 취소 / 임의 환불</b>
      </p>

      <p>
        임의 취소 / 임의 환불은 튜터가 아이고고 약관이 정하는 운영정책 기준 상의 합당한 이유 없이, 특정 회원과의 클래스
        약속을 일방적으로 취소하고 환불시키는 것을 뜻합니다. 단, 클래스의 취소 사유를 회사에게 통보하여 합리적 판단에
        의해 클래스 서비스 제공을 취소하고 환불할 필요가 있다고 양측이 인정하는 경우는 예외로 합니다.
      </p>

      <p>- 1차 : 1차 경고 조치 및 해당 클래스 선택사항 조정이나 일시 등록 중단 조치</p>

      <p>- 2차 : 2차 경고 조치 및 신규 클래스 등록 제한</p>

      <p>- 3차 : 3차 경고 조치 및 정산 중지</p>

      <p>③ 연락두절</p>

      <p>
        연락두절이란 회원이 회원가입 시 기재한 유선 또는 메일(e-mail)등 기타의 방법을 통하여 회사가 연락을
        시도하였음에도 불구하고, 48시간 동안 일체의 응대가 진행되지 않는 상태를 말합니다. 튜터는 정상적인 클래스 활동이
        불가한 상태 또는 이와 같은 상태가 예상되는 경우 이를 즉시 회사에 통보하여야 하며, 전자상거래 등에서의
        소비자보호에 관한 법률 등 관련법에 따른 취소, 환불, A/S 등의 신속한 처리를 위하여 최선의 노력을 다하여야 합니다.
        회원의 연락두절로 인한 클레임이 발생하였을 경우, 회사는 아래의 규정에 따라 조치할 수 있습니다. 단, 천재지변이나
        불가항력적인 사유가 있는 경우는 예외로 합니다.
      </p>

      <p>- 연락두절 1차 : 1차 경고 및 개선요청</p>

      <p>
        - 연락두절 2차 : 1차 경고 후 24시간 이상 일체의 응대가 진행되지 않는 상태를 연락두절 2차라 칭하며, 연락두절
        2차의 경우 취할 수 있는 조치는 2차 경고, 직권에 따른 환불처리 및 해당 튜터의 전 클래스 등록 중지, 신규클래스
        등록제한, 해당 부모님의 구매/열람/회원활동 제한 등임.
      </p>

      <p>
        - 연락두절 3차 : 2차 경고 후 24시간 이상 일체의 응대가 진행되지 않는 상태를 연락두절 3차라 칭하며, 연락두절
        3차의 경우 취할 수 있는 조치는 정산 및 환불 중지 및 이용계약 해지 등임.
      </p>

      <p>
        ※ 1차 경고 후 3개월 내에 연락두절 상태가 재발될 경우, 경고 없이 바로 연락두절 2차 조치를 취할 수 있습니다. 2차
        경고 후 3개월 내에 연락두절 상태가 재발될 경우, 경고 없이 바로 연락두절 3차 조치를 취할 수 있습니다.
      </p>

      <p>④ 부모님 및 아이의 문의 처리 지연(미처리) 및 불만족</p>

      <p>
        튜터는 부모님 및 아이의 문의(아이고고 모바일 앱 내 채팅 메세지 등을 통한)를 신속하고 성실히 처리해야 합니다.
        회사는 일정기한 내 튜터의 답변의 처리 유무에 따라 처리율을 판단하고, 튜터 답변의 부모님 및 아이 만족도를
        조사하고 그 성과를 판단할 수 있습니다. 부모님 및 아이 문의에 대한 튜터의 처리율 및 만족도가 저조한 경우 회사는
        아래의 규정에 따라 조치할 수 있습니다.
      </p>

      <p>- 1차 부모님 및 아이 문의 처리 지연 및 불만족 : 경고 및 개선요청</p>

      <p>- 2차 부모님 및 아이 문의 처리 지연 및 불만족 : 해당 클래스 등록 중지 및 개별 CS 가이드</p>

      <p>- 3차 부모님 및 아이 문의 처리 지연 및 불만족 : 전 클래스 등록 중지 및 신규 클래스 등록 제한</p>

      <p>
        <b>6) 추가 계정을 통한 클래스 등록</b>
      </p>

      <p>
        회원이 추가(개인) 계정을 사용하여 최초 가입 계정과 동일한 대분류 카테고리 내에 클래스 등록을 하는 경우, 회사는
        회원에게 회원자격 정지, 클래스 등록 제한, 등록 클래스 노출 순위(이하 “인기도”) 감점, 클래스 등록 가능개수 제한
        등의 페널티를 부과할 수 있습니다.
      </p>

      <p>
        <b>7) 기타 위법·부당행위</b>
      </p>

      <p>
        회사는 회원이 다음 각 목의 하나에 해당하는 경우 회원자격을 정지하거나 서비스 제공을 거부 또는 제한할 수 있으며,
        그 밖에 법령준수, 타인의 권리보호, 사이트의 안전 확보 등을 위하여 필요한 법적 조치를 취할 수 있습니다.
      </p>

      <p>
        ① 회사의 아이고고 사이트, 아이고고 모바일 앱, 시스템, 서비스를 스팸정보 전송 또는 악성코드 프로그램 유포,
        타인계정 도용 등 범죄 또는 불법행위에 이용한 경우
      </p>

      <p>
        ② 튜터 - 부모님 및 아이 상호간의 합의를 통해 체결된 클래스 이행 또는 체결된 클래스의 수강료(수업료)를 결제해야
        할 때, 무단으로 잠적 / 회피 하는 경우
      </p>

      <p>
        ③ 거래이행을 위한 부모님 및 아이 또는 회사로부터의 연락 회피나 제재 회피 등, 부정한 목적으로 회원정보의 수정을
        거부하거나 해태한 경우
      </p>

      <p>④ 게시판(예시: 블로그) 등을 통하여 불법정보를 유통하거나 유통을 시도한 경우</p>

      <p>
        ⑤ 아이고고 사이트, 아이고고 모바일 앱의 정보, 데이터를 정당한 권한 없이 스스로 또는 제3자를 통하여 무단 전재,
        복제, 게시(복사, 퍼가기, 스크래핑 등)하거나 기타의 방법으로 상업적으로 이용한 경우
      </p>

      <p>
        ⑥ 회원이 제공한 정보 또는 그에 관한 증빙자료가 허위이거나, 회사가 요청하는 증빙자료를 정당한 사유 없이 제공하지
        않는 경우
      </p>

      <p>
        ⑦ 수출 관리 법령과 게시된 규칙, 제한을 위반하여 아이고고의 웹/앱 서비스 및 관련 툴을 수출 또는 재수출하는 경우
      </p>

      <p>⑧ 아이고고 서비스에 관련된 정보나 소프트웨어를 정당한 논의 및 계약 절차 없이 상업화하는 경우</p>

      <p>⑨ 회원이 다른 회원의 개인정보를 무단수집 하거나 마케팅 등 수집 목적 외로 이용하는 경우</p>

      <p>
        ⑩ 회원이 다른 회원의 저작권, 초상권 등의 권리를 침해하거나 개인정보를 무단으로 제3자에게 제공하는 등 관련법령
        또는 회사의 개인정보취급방침을 위배하는 경우
      </p>

      <p>
        ⑪ 아이고고의 채팅 또는 거래메세지를 이용한 대화, 구매평가(클래스 수강 후기, 앱 이용 후기 등) 및 댓글 등의 내용
        중 욕설, 비방 혹은 명예를 훼손하는 내용을 작성하는 경우
      </p>

      <p>
        ⑫ 그 외 약관 또는 법령에 위반되거나 타인의 권리를 침해하는 행위가 있거나, 그러한 위법·부당한 행위가 있는 것으로
        의심될 만한 상당한 이유가 있는 경우
      </p>

      <p>
        <b>8) 후기(리뷰) 및 커뮤니티 사용 정책</b>
      </p>

      <p>
        <b>
          회사는 서비스와 클래스에 관한 후기를 작성할 수 있는 후기(리뷰)와 커뮤니티 장소를 제공하고 있습니다. 회원이
          작성한 내용들이 다음 각 목의 하나에 해당하는 경우 회원자격을 정지하거나 서비스 제공을 거부 또는 제한할 수
          있으며, 그 밖에 법령준수, 타인의 권리보호, 사이트의 안전 확보 등을 위하여 필요한 민/형사상의 법적 조치를 취할
          수 있습니다.
        </b>
      </p>

      <p>① 작성 내용은 튜터 / 클래스와 관련한 주제에서 벗어나지 않아야 합니다.</p>

      <p>② 입력란에 상품 URL, 제휴 코드, 이메일 주소 및 개인 SNS 관련 정보 또는 링크를 포함할 수 없습니다.</p>

      <p>
        ③ 평점을 높이거나 낮출 의도로 허위 리뷰를 출시하거나 강요할 수 없습니다. 만일 허위 리뷰를 작성, 권유한 정황이
        포착될 경우 회원자격이 박탈될 수 있으며, 튜터의 경우 클래스 삭제까지 진행 할 수 있습니다.
      </p>

      <p>
        ④ 튜터는 클래스에 관한 자료나 기타 조건 등을 통해 리뷰 작성을 요구하거나 강제적으로 높은 평점을 요구하는 리뷰를
        할 수 없습니다.
      </p>

      <p>⑤ 노골적인 성적 표현이나 욕설이 포함된 내용을 등록해서는 안됩니다.</p>

      <p>⑥ 튜터와 회원을 모욕, 증오, 위협, 또는 희롱하는 내용을 등록해서는 안됩니다.</p>

      <p>
        ⑦ 다른 사람으로 가장하거나 다른 사람 또는 법인과의 제휴 관계를 허위로 기술 또는 표시해서는 안됩니다. 또한,
        타인에 대한 예의를 갖추며 공격하지 않아야 합니다.
      </p>

      <p>
        ⑧ 리뷰는 클래스 당 1번만 작성할 수 있습니다. 최초 작성 이후에는 수정만이 가능합니다. 회원이 별도의 조치를 원할
        경우, 회사 측에 통보합니다.
      </p>

      <p>
        <b>9) 아이고고 서비스 내에서 제공하는 1:1 대화(채팅 등) 기능을 악용하는 행위</b>
      </p>

      <p>
        <b>
          회원은 튜터와 직접적으로 1:1 대화를 시도할 수 있습니다. 회원이 작성한 내용들이 다음 각 목의 하나에 해당하는
          경우 회원자격을 정지하거나 서비스 제공을 거부 또는 제한할 수 있으며, 그 밖에 법령준수, 타인의 권리보호,
          사이트의 안전 확보 등을 위하여 필요한 민/형사상의 법적 조치를 취할 수 있습니다.
        </b>
      </p>

      <p>
        ① 회원은 아이고고 서비스 내 제공하는 채팅 기능을 활용하여 특정 서비스 및 상품의 홍보 행위를 할 수 없으며,
        구인·구직·섭외·영업 등의 제안 행위를 진행할 수 없습니다.
      </p>

      <p>② 회원은 공공질서 및 미풍양속에 위반 되는 내용과 첨부파일(이미지 등)을 전달해서는 안됩니다.</p>

      <p>③ 회원이 상대방의 업무를 방해할 목적으로 스팸성 메시지를 발송해서는 안됩니다.</p>

      <p>
        ④ 회원 간 아이고고 서비스를 활용한 특정 상품 추천, 서비스 추천 및 매매 유도, 강매 등 일체 행위를 금합니다. (보험
        상품, 주식 종목 추천, 부동산 매매, 화장품, 건강보조제, 정수기, 서적, 각종 회원권 및 이용권, 타 서비스의 회원
        가입 유도, 부업 유도, 자격증, 수수료 발급 등의 일체 행위 포함)
      </p>

      <p>
        <b>10) 아이고고 서비스 상담 시 문제 행위</b>
      </p>

      <p>
        <b>
          회원은 아이고고의 임직원, 상담원, 피용자, 업무수탁자와 서비스에 관해 문의할 시, 모욕감을 줄 수 있는 행위를
          금합니다. 외설, 폭력적인 메시지, 기타 공공질서 및 미풍양속에 반하는 표현 모두를 금하며 이를 어길 시 영업방해에
          의한 민사, 형사적 법적 절차를 진행할 수 있습니다.
        </b>
      </p>

      <p>
        <b>제9조 (대리 및 보증의 부인)</b>
      </p>

      <p>
        가. 회사는 통신판매중개자로서 회원 상호간의 거래를 위한 온라인 거래장소(marketplace)를 제공할 뿐이므로, 클래스를
        제공하거나 구매하고자 하는 회원을 대리하지 않습니다. 또한, 회사의 어떠한 행위도 튜터 또는 부모님 및 아이를
        대리하는 행위로 간주되지 않습니다.
      </p>

      <p>
        나. 회사는 중개서비스를 통하여 이루어지는 회원간의 판매 및 구매와 관련하여 판매의사 또는 구매의사의 존부 및
        진정성, 등록물품의 품질, 완전성, 안전성, 적법성 및 타인의 권리에 대한 비 침해성, 회원이 입력하는 정보 및 그
        정보를 통하여 링크된 URL에 게재된 자료의 진실성 등 일체에 대하여 보증하지 아니하며, 이와 관련한 일체의 위험과
        책임은 해당 회원이 부담해야 합니다.
      </p>

      <p>
        다. 회사는 회원이 게재하는 클래스설명 등의 제반 정보를 통제하거나 제한하지 않습니다. 다만, 회사는 회원이 게재한
        정보의 내용이 타인의 명예, 권리를 침해하거나 법규정을 위반한다고 판단하는 경우에는 이를 삭제할 수 있고, 클래스
        취소, 중지 등 기타 필요한 조치를 취할 수 있습니다. 또한, 클래스의 원활한 홍보 등을 위한 상호호혜적 목적의 경우
        클래스 제목 및 내용의 수정을 진행할 수 있으며 회원이 이를 원치 않을 시 강제하지 않습니다.
      </p>

      <p>
        <b>제10조 (아이고고 안전결제서비스)</b>
      </p>

      <p>
        가. 회사는 중개서비스를 제공하는 과정에서 클래스 결제금액에 대한 대금 수취, 보관 및 환불로 이루어지는 에스크로
        방식의 아이고고 안전결제서비스를 제공합니다. 이러한 아이고고의 안전결제서비스는 중개서비스를 통하여 이루어지는
        회원 상호간의 거래의 안전성과 신뢰성을 도모하고 회원을 보호하기 위한 목적에서 제공하는 장치이므로, 회사가
        아이고고 안전결제서비스를 통하여 튜터 또는 부모님 및 아이를 대리, 대행하거나 그 이행을 보조하는 것은 아닙니다.
      </p>

      <p>
        나. 회사가 제공하는 아이고고 안전결제서비스는 기본적인 중개서비스에 포함됩니다. 회원이 클래스 결제에서 아이고고
        안전결제서비스를 통하지 않는 직거래를 유도하는 경우, 회사는 신청취소, 판매중지 등 기타 필요한 조치를 취할 수
        있습니다.
      </p>

      <p>
        다. 아이고고 안전결제서비스의 일환으로 이루어지는 대금보관으로 인하여 회사가 취득하는 이자 등은 서비스 제공의
        대가이므로, 회원은 회사에 대하여 이자 등의 반환을 청구할 수 없고 대금 송금(실시간계좌이체, 가상계좌 등)으로
        인하여 발생하는 수수료는 대금을 송금하는 회원이 부담하여야 합니다.
      </p>

      <p>
        라. 회사가 제공하는 아이고고 안전결제서비스를 이용하지 않은 거래 또는 아이고고 안전결제서비스가 포괄하는
        클래스의 내용들이 종결된 거래의 경우, 해당 거래와 관련하여 발생한 모든 사항은 튜터와 부모님 및 아이가 상호협의를
        통해 해결하여야 합니다.
      </p>

      <p>
        마. 튜터는 회사가 제공하는 서비스를 이용함에 있어서 아이고고 안전결제서비스의 이용과 그 규칙에 동의하여야
        합니다.
      </p>

      <p>
        바. 회사가 제공하는 아이고고 안전결제서비스를 오용, 악용함으로써 사회질서, 미풍양속을 침해하거나 침해할 우려가
        있다고 판단되는 경우 또는 회사가 아이고고 안전결제서비스를 제공하지 못할 상황 또는 사유가 발생하는 경우 회사는
        클래스 판매를 제한할 수 있습니다.
      </p>

      <p>
        <b>제11조 (튜터의 의무)</b>
      </p>

      <p>
        가. 튜터는 이 약관과 회사가 서비스화면을 통하여 미리 공지하는 개별약관이 정하는 절차에 따라 신원정보의 제공,
        클래스의 등록, 거래진행 과정의 관리, 부모님 및 아이에 대한 클래스 이행, 또는 환불 등의 사후처리를 수행하여야
        합니다.
      </p>

      <p>나. 튜터는 회원의 질문에 성실히 응해야 합니다.</p>

      <p>
        다. 회사 및 튜터는 부모님 및 아이의 행위가 허위 또는 불법 결제에 해당하는 경우 및 신속한 거래관계의 확정 또는
        거래의 안전을 위하여 필요한 경우, 그 밖의 정당한 사유가 있는 경우 거래를 취소할 수 있습니다. 다만, 튜터가 예상치
        못한 클래스 이행 불능 등으로 정상적 거래 이행이 곤란하여 거래를 취소하는 경우에는 지체 없이 부모님 및 아이에
        대한 통지, 환급(에 필요한 조치) 등 관계법령이 정한 조치를 취하여야 합니다.
      </p>

      <p>
        <b>
          라. 튜터는 부모님 및 아이의 승인요청을 거절하는 경우, 48시간 이내 거절의사와 함께 불가사유를 부모님 및
          아이에게 전달하여야 합니다. 정해진 시간내 불가사유 관련 의사를 전달하지 않을 경우, 회사는 튜터에게 활동정지 및
          회원 자격 상실 등의 조치를 취할 수 있습니다.
        </b>
      </p>

      <p>
        마. 튜터의 클래스 등록은 튜터 등록 서비스 화면상의 양식에 따라야 하며, 클래스 등록양식에 어긋난 등록이나
        허위등록의 경우에는 해당 클래스를 삭제, 취소하거나 중개를 하지 않을 수 있으며, 이에 따른 모든 책임은 해당 튜터가
        부담하여야 합니다.
      </p>

      <p>
        바. 튜터는 클래스 등록 시 불법적인 거래를 유도하는 문구 및 부모님 및 아이의 정당한 권리(아이고고 환불정책 등)를
        제한하거나, 허위 또는 기타 법령이나 약관에 위배되는 내용(문구, 사진 또는 설명을 포함)을 등록해서는 아니 되며,
        스스로 또는 다른 회원을 통하여 고의로 구매평가, 클래스 진행횟수 등을 조작하거나 기타 판매가장등록 등
        중개서비스의 안전성과 신뢰성을 해하는 부정한 행위를 하여서도 안됩니다. 이를 위반한 경우, 회사는 관련 회원이나
        해당 거래에 대하여 거래취소, 클래스 중지 및 기타 필요한 조치를 취할 수 있습니다.
      </p>

      <p>
        사. 튜터는 회사의 명칭, 로고 및 회원의 회원등급 표시를 회사가 정한 목적 이외의 목적으로 임의로 사용하거나 지정된
        곳 이외의 장소에 표시하여서는 안되며, 이러한 행위로 인하여 회사 또는 타 회원에게 피해가 발생한 경우 이에 대하여
        일체의 법적 책임을 부담하여야 합니다.
      </p>

      <p>
        아. 튜터는 회사의 아이고고 웹 사이트, 아이고고 모바일 앱에서의 거래와 관련하여 전자상거래 등에서의 소비자보호에
        관한 법률 등 관계법령에서 명시한 자료를 보관하여야 합니다.
      </p>

      <p>
        자. 튜터는 자신의 튜터 정보란에 회사가 정하는 절차에 따라 인증 받은 휴대폰 번호를 대표번호로서 설정하고 항상
        최신 정보로서 유지하여야 합니다. 또한, 대표번호 변경 시 새로이 인증을 받아야 합니다. 튜터가 본 항의 의무를
        이행하지 않을 경우, 회사는 이행 완료 시까지 해당 튜터의 클래스 등록과 편집을 제한할 수 있습니다.
      </p>

      <p>
        차. 튜터는 통신판매중개 의뢰 및 그와 관련한 계약이행 과정에서 알게 된 튜터 등 다른 회원의 개인정보를 법률, 이
        약관 또는 회사의 개인정보취급방침에서 정한 목적 외의 용도로 사용할 수 없으며, 이를 위반할 경우, 모든 법적 책임을
        지고 자신의 노력과 비용으로 회사를 면책시켜야 하고 회원자격이 정지 또는 박탈될 수 있습니다.
      </p>

      <p>
        카. 회사의 고의 또는 과실과 무관하게, 특정 튜터가 관계하고 있는 다른 회원 또는 제3자의 개인정보가 침해된 경우
        회사는 그에 대하여 책임을 지지 않습니다.
      </p>

      <p>타. 신청한 부모님 및 아이에 대한 의무를 다합니다.</p>

      <p>
        <b>1) 튜터는 모든 클래스에 있어 성심과 성의를 다하여 부모님을 응대하고 아이를 지도해야 합니다.</b>
      </p>

      <p>
        <b>
          2) 튜터는 부모님 및 아이의 사전 동의 없이 클래스에 관한 조건(클래스 횟수, 일정, 1회 당 클래스 시간 등)을
          일방적으로 변경할 수 없으며, 클래스 시작 1주일 전까지는 변경 사항을 알려 동의를 받아야 합니다. 만일 부모님 및
          아이가 동의하지 않는 경우 수령한 수강료(수업료)를 모두 환불해야 합니다.
        </b>
      </p>

      <p>
        <b>
          3) 튜터는 클래스를 결제받아 진행하고 있는 상황에서 해당 클래스의 수강료(수업료)를 변경할 수 없습니다. 이는
          기존 클래스를 수강하고 있는 부모님 및 아이에게 변동성을 최소화 하기 위한 조치입니다. 수강료 변경은 진행중인
          클래스가 모두 종료되어 정산이 완료된 시점부터 가능합니다.
        </b>
      </p>

      <p>
        <b>
          4) 하나의 클래스에서 2회 이상의 수강료 변경이 발생할 시, 회사에서는 튜터를 대상으로 유선∙대면 등의 상담과정을
          거쳐 사유 파악을 바탕으로 후속 조치를 취할 수 있습니다.
        </b>
      </p>

      <p>
        <b>
          5) 튜터는 자신의 신상정보가 변경되었을 경우, 즉시 자신의 회원정보를 수정하여야 하며 수정하지 않아 발생하는
          불이익은 튜터가 부담하게 됩니다.
        </b>
      </p>

      <p>
        <b>6) 튜터는 서비스와 관련하여 다음 사항을 해서는 안됩니다.</b>
      </p>

      <p>① 다른 이용자의 계정을 부정 사용하는 행위</p>

      <p>② 다른 이용자의 개인정보를 수집, 저장하는 행위</p>

      <p>③ 회사 직원, 운영자 등을 포함한 타인을 사칭하는 행위</p>

      <p>④ 서비스의 운영에 지장을 주거나 줄 우려가 있는 일체의 행위</p>

      <p>⑤ 본 약관을 포함하여 기타 당사가 정한 규정 또는 이용조건을 위반하는 행위</p>

      <p>⑥ 객관적으로 범죄와 결부된다고 판단되는 행위</p>

      <p>⑦ 당사와 부모님 및 아이와의 협의 없이 제3자를 클래스에 대리 참석시키는 행위</p>

      <p>⑧ 기타 관계법령에 위배되는 행위</p>

      <p>
        5) 튜터는 클래스 진행 시, 명시한 최소 인원이 모집 되지 않을 경우 해당 클래스에 폐강을 진행할 수 있습니다. 이
        경우에 클래스 진행이 예정되어 있던 부모님 및 아이에게는 클래스 진행 시각 24시간전까지 부모님 및 아이에게 클래스
        진행여부를 부모님 및 아이에게 고지하여야 하며 사유를 필수적으로 알려야 합니다.
      </p>

      <p>
        6) 튜터는 클래스 종료 후, 리포트 작성을 통해 자동으로 수강료를 정산받을 수 있습니다. 클래스 진행이 완료된 시점에
        아이고고 웹/앱 서비스의 수강료 정산 메뉴를 이용하여 리포트 작성을 진행하면 자동으로 수강료 정산신청이
        완료됩니다. 클래스가 정상적으로 완료되기 이전에는 리포트를 작성할 수 없습니다. 만일 정산 진행 이후 클래스가
        정상적으로 진행되지 않은 사실이 확인 될 경우, 환급 받은 비용은 전액 아이고고 회사로 입금하고 아이고고 약관에
        따라 정상적인 절차를 진행해야 합니다. 또한, 이에 따라 튜터는 클래스의 노출도 감소, 클래스 중단 등 불이익을 받을
        수 있습니다.
      </p>

      <p>
        파. 튜터가 성실히 클래스를 이행하지 않거나 일방적으로 계약을 파기할 경우, 당사는 관계법령에 의해 선생님을 고소,
        고발 조치 및 회원 자격박탈 등을 취할 수 있으며, 해당 튜터는 이에 대한 모든 법적 책임을 감수합니다. 또한, 부모님
        및 아이로부터의 클래스 전문성에 대한 의문 제기, 클래스에 대한 불충분한 준비, 지각 및 무단 연락 두절에 대하여
        신고 건수에 따라 다음과 같은 삼진아웃 조항을 적용합니다.
      </p>

      <p>① 2건 이상의 부모님 및 아이 신고 접수 시 : 경고 조치 및 경중에 따른 회사의 별도 조치 가능</p>

      <p>② 3건 신고 접수 시 : 잠정적 영구 클래스 중단 조치 및 경중에 따른 회사의 별도 조치 가능</p>

      <p>
        하. 튜터의 계정 공유 및 계정 재판매, 유/무상 양도 등을 금지합니다. 금지된 행위를 한 회원은 당사의 개인정보
        불법유출, 영업방해 및 이미지 훼손에 대한 손해배상을 해야 하며, 계정은 사전통보 없이 강제로 자격 박탈 처리됩니다.
      </p>

      <p>
        <b>제12조 (튜터의 등록 및 수정)</b>
      </p>

      <p>가. 등록 자격</p>

      <p>
        1) 튜터는 약관에 따라 클래스를 등록할 수 있습니다. 다만, 회사는 소비자 보호 및 서비스 수준 유지를 위해 일부
        카테고리 클래스의 경우 클래스를 등록할 수 있는 튜터의 자격을 제한할 수 있습니다.
      </p>

      <p>2) 튜터는 튜터 회원 본인의 연령이 만 20세 이상인 경우부터 클래스 등록 및 진행을 할 수 있습니다.</p>

      <p>
        3) 튜터는 클래스의 등록 시 부모님 및 아이가 클래스에 대한 정보를 확인할 수 있도록 다음과 같은 사항을 정확하게
        기재하여야 하며, 회사가 승인하지 아니한 시스템을 통하여 회사 정보통신망에 무단 접속하여 자동으로 등록하는
        방법으로 클래스를 등록할 수 없습니다. 회사는 클래스 검색의 효율성, 시스템에 걸리는 부하 등을 고려하여 튜터에
        대한 사전 통지로써 튜터 1인당 클래스 등록 건수를 제한할 수 있습니다. 튜터가 회사의 클래스 등록 건 수 제한조치에
        반하여 클래스등록을 한 경우, 회사는 등록된 클래스를 중지하거나 삭제할 수 있고 위반 횟수 및 수준에 따라 해당
        클래스를 등록한 튜터의 회원자격을 정지하거나 서비스 이용을 제한할 수 있습니다.
      </p>

      <p>
        4) 외국인 회원의 경우 현재 대한민국에 거주 및 체류하고 있는 외국인 중, 한국에서 취업 가능 비자를 소유해야 하며,
        비자 인증을 통해서 튜터로 활동 가능합니다. 혹은 국내 체류에 대해 허가를 받은 ‘외국인 등록증(주민등록번호가
        발행된)' 증명이 가능한 경우에는 가능합니다. 단, 'E-2 회화 지도 비자'의 경우에는 외국어 전문 관련시설,
        어학연구소, 연수원 등에서 가능하며 아이고고에서 튜터로는 활동이 불가합니다.
      </p>

      <p>① 수강료(수업료, 클래스 이용료)</p>

      <p>
        튜터는 등록하고자 하는 수강료를 명확히 기재하여야 합니다. 수강료는 시간 당/회 및 전체 커리큘럼의 총 횟수를
        입력하여 설정할 수 있습니다. 튜터가 책정한 수강료는 아이고고 플랫폼 이용 ‘수수료‘를 제한 뒤 튜터에게 정산되며,
        ‘수수료‘의 책정 기준은 다음과 같습니다. 수강료에 할인이 적용되는 경우, 할인이 적용된 액수를 기준으로 수수료를
        제한 뒤 정산됩니다.
      </p>
      <p>
        - 개인고객 수수료 : 수강료의 15% 개인고객이란, 수강료를 지불하는 주체가 공공 또는 민간 기관이나 법인사업자가
        아닌 ‘개인이나 대중(부모님, 양육자 등)’인 경우를 의미합니다.
      </p>
      <p>
        - 기관고객 수수료 : 수강료의 20% 기관고객이란, 수강료를 지불하는 주체가 공공 또는 민간 기관이나 법인사업자인
        경우를 의미하며 아이고고를 통한 튜터의 기관 출강 등에 적용되는 기준입니다.
      </p>

      <p>② 클래스의 상세정보</p>

      <p>
        클래스에 대한 상세정보는 사실대로 명확하게 기재하여야 합니다. 허위 또는 과장 정보를 기재한 경우, 모순되는 내용의
        정보가 기재되어 있는 경우, 이 약관에 반하는 거래조건이 기재된 경우 회사는 해당 클래스를 취소하거나 중지 시킬 수
        있습니다. 이와 관련하여 취소 또는 중지된 클래스에 관한 판매서비스수수료는 환불되지 않으며, 중개의 취소 또는
        중지로 인한 모든 위험과 책임은 클래스를 등록한 해당 튜터가 부담해야 합니다. 클래스가 취소되거나 중지되면, 회사는
        전화 또는 메일(e-mail) 등의 방법을 통하여 해당 부모님 및 아이에게 해당 사실을 통지합니다.
      </p>

      <p>③ 추가 정보</p>

      <p>
        (1) 튜터는 클래스 등록 시 튜터가 클래스 정보 제공고시에 따른 정보를 입력하지 않거나, 클래스 등록 후 변경된
        클래스 정보 제공고시에 따라 정보를 수정, 보완하지 않는 경우 회사는 이 약관의 제15조 다항에 따라 회원자격의 정지,
        서비스 제공 거부·제한 등 필요한 조치를 취할 수 있습니다.
      </p>

      <p>
        (2) 튜터는 등록 클래스에 특별한 거래조건이 있거나, 추가클래스, 장소 및 장비 대여비용 등 추가되는 비용이 있는
        경우, 이를 부모님 및 아이가 알 수 있도록 명확하게 기재하여야 합니다. 다만, 이러한 특별한 거래조건은 부모님 및
        아이의 정당한 권리를 제한하거나 이 약관을 위반하는 경우, 그외 회사가 미리 정한 거래조건에 위배되어서는 안됩니다.
      </p>

      <p>
        (3) 클래스는 등록 이후에도 수정이 가능합니다. 일신의 문제로 인하여 진행이 불가능한 사유가 있는 경우에는 아이고고
        웹·앱에서 운영중지를 하거나 고객센터에 이 사실을 알림으로써 일시적으로 클래스를 중지할 수 있습니다. 수정되거나
        추가된 내용이 부모님 및 아이에게 불리한 경우, 수정 이전에 클래스를 신청한 부모님 및 아이에게는 추가 내용 등록
        전의 내용이 적용됩니다.
      </p>

      <p>
        (4) 튜터는 클래스 등록을 함에 있어서 저작권, 초상권 등 제3자의 권리를 침해해서는 안 됩니다. 튜터가 등록한 클래스
        정보는 판매 활성화를 위하여 회사가 제휴한 사이트와 다른 회원의 블로그 등에 노출될 수 있습니다. 다만, 다른 회원의
        블로그 등에의 노출은 다른 회원이 회사가 정한 이용규칙에 동의하고 회사가 허용한 방식으로 공유하는 경우에만
        가능합니다.
      </p>

      <p>
        (5) 튜터가 튜터 본인의 계정 또는 다른 회원의 계정을 이용하여 클래스 및 거래조건이 실질적으로 동일한 클래스
        ("동일 클래스")를 두 개 이상 중복하여 등록할 수 없습니다. 이에 위반한 클래스 등록에 대해 회사는 클래스검색의
        효율성 제고 등을 위해 일정한 절차를 거쳐 중복 등록된 클래스를 등록중단 또는 삭제 처리 할 수 있고, 위반 횟수 및
        수준에 따라 해당 클래스를 등록한 튜터의 회원자격을 정지 또는 서비스 이용을 제한하거나 추천도를 감점할 수
        있습니다.
      </p>

      <p>
        (6) 튜터는 튜터 등록 및 클래스 등록·수정 시에 튜터의 개인정보 보호, 타인의 튜터정보 악용사례 방지 및 부모님 및
        아이의 안전한 거래를 보장하기 위한 아이고고 시스템이 허용하지 않는 범위의 연락처를 기입할 수 없습니다. 튜터와
        부모님 및 아이들의 개인정보 권리를 보호하고 아이고고 시스템의 권리 보장을 통하지 않은 개인간 거래를 방지하기
        위하여, 전화번호(유선/무선)·이메일 연락처·메신저·SNS ID 등 연락처의 기재를 금합니다. 튜터 클래스 상세 페이지
        뿐만 아니라 리뷰, 이미지·영상, 채팅 등 다른 게시판 또한 연락처를 기재할 수 없으며, 이를 위반할 시 회사에서는
        해당 문구 및 내용을 숨김(블록) 또는 삭제 처리할 수 있고 위반 횟수 및 수준에 따라 해당 클래스를 등록한 튜터의
        회원자격을 정지 또는 서비스 이용을 제한할 수 있습니다.
      </p>

      <p>
        (7) 튜터는 클래스의 인원과 가격 설정 시, 시간당 총 수익을 해당년도 근로기준법이 정한 최저임금 이상으로 고려하여
        설정해야 합니다.<b> (참고 : 2021년 1월 20일 기준 8,720원)</b>
      </p>

      <p>
        (8) 튜터는 회원 탈퇴 시, 언제든지 회사에게 언제든지 회사에게 의사를 전달함으로써 이용 계약을 해지할 수 있습니다.
        이에 따라 회사는 온라인으로 탈퇴할 수 있는 절차를 제공합니다. 단, 탈퇴 요청 이전, 진행해야 할 클래스가 있는 경우
        부모님 및 아이과 함께 클래스 이행 혹은 취소 사항을 합의 해야 합니다. 부모님 및 아이와의 모든 클래스 절차를
        마무리하고, 회사와 서비스 이용에 따른 상호간의 채무 또한 청산하여야 합니다. 이 경우 거래 건의 철회 또는 취소로
        인한 불이익은 튜터 본인이 부담하여야 합니다.
      </p>

      <p>
        (9) 튜터는 아이고고 서비스를 활용한 특정 상품 추천, 서비스 추천 및 매매 유도, 강매 등 일체 행위를 할 수
        없습니다. 해당 사항이 확인 될 경우 회사는 튜터에게 관련 자료를 요청할 수 있으며, 근거 자료를 통해 클래스 승인 및
        튜터 활동 여부에 대한 조치를 취할 수 있습니다. (보험 상품, 주식 종목 추천, 부동산, 화장품, 식품, 건강보조제,
        정수기, 서적, 각종 회원권, 서비스 이용권, 회원 가입 유도, 부업 유도, 자격증, 수료증 발급 등의 일체 행위)
      </p>

      <p>(10) 튜터가 클래스를 삭제할 경우, 클래스는 복구되지 않습니다.</p>

      <p>
        <b>제13조 (부모님 및 아이의 권리)</b>
      </p>

      <p>가. 부모님 및 아이는 자신의 개인 신상정보를 보호 받을 권리가 있습니다.</p>

      <p>나. 부모님 및 아이는 자신의 조건에 적합한 튜터를 직접 검색하고 클래스를 신청할 수 있습니다.</p>

      <p>
        다.{' '}
        <b>
          부모님 및 아이는 튜터의 개인적인 사유로 사전 예고없이 일방적으로 클래스를 중단받을 경우, 지급한 수강료 전부에
          대한 금액을 튜터에게 환불 받을 수 있으며, 환불금액 송금은 회사가 일임합니다.
        </b>
      </p>

      <p>
        <b>제14조 (부모님 및 아이의 의무)</b>
      </p>

      <p>
        가. 부모님 및 아이는 관계법령, 본 약관의 규정, 이용안내 및 서비스 상에 공지한 주의사항, 당사가 통지한 사항을
        준수해야 하며, 기타 회사의 업무에 방해되는 행위를 해서는 안됩니다.
      </p>

      <p>
        나. 부모님 및 아이는 튜터의 사전 동의 없이 클래스에 관한 조건(클래스 횟수, 일정, 1회 당 클래스 시간 등)을
        일방적으로 변경할 수 없습니다.
      </p>

      <p>
        다. 부모님 및 아이는 가입시 기재한 정보에 변동이 생길 경우, 회원정보 수정 및 관련 문의 접수를 위해 정보를
        최신으로 갱신을 해야 합니다. 이를 진행하지 않아 발생하는 불이익에 대한 모든 책임은 부모님 및 아이에게 있습니다.
      </p>

      <p>
        라. 튜터로부터 부모님 및 아이의 불쾌한 언어 사용 및 행동, 클래스과 관련없는 요청 및 이상 행동에 대한 신고가
        접수되는 경우, 당사는 부모님 및 아이에게 다음과 같은 조치를 취합니다. 단, 중대한 범죄와 관련될 수 있는 사항으로
        판단되는 경우, 민·형사상 소송을 제기할 수 있으며 당사는 즉시 회원자격의 영구 탈퇴 조치를 진행할 수 있습니다.
      </p>

      <p>
        <b>- 1건 신고 접수 시: 당사에서 상황에 대해 파악한 후, 경고조치</b>
      </p>

      <p>
        <b>- 2건 신고 접수 시: 회원 자격 일시 정지 및 회사 측과 유선, 대면 상담 진행</b>
      </p>

      <p>
        <b>- 3건 신고 접수 시: 회원 자격의 영구 정지</b>
      </p>

      <p>
        마. 부모님 및 아이는 튜터의 강의 내용 및 강의자료, 판매자료 일체를 수취하여 재판매를 목적으로 하는 행위를
        금합니다. 또한 지적재산권에 대한 침해, 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위를
        금지합니다.
      </p>

      <p>
        바. 자신을 제외한 아이고고의 모든 회원이 서비스 내 게시한 정보를 회사와 타 회원 양측의 사전 승낙 없이 영리 또는
        비영리의 목적으로 다른 회사에 제공할 수 없습니다.
      </p>

      <p>
        사. 부모님 및 아이는 거래 의사 없이 구매와 취소를 반복해서는 안됩니다. 부모님 및 아이 회원이 거래 의사 없이
        의도적으로 반복적으로 구매와 취소를 반복한다고 판단할 시, 아이고고는 전체 튜터에게 해당 사실을 공지하고, 해당
        회원의 회원 자격 잠정 중지 및 영구적 박탈 등의 조치를 취할 수 있습니다.
      </p>

      <p>
        <b>제15조 (당사의 권리)</b>
      </p>

      <p>
        가. 당사는 클래스 연결의 신뢰도를 제고하기 위해, 튜터로부터 학생증, 재학/졸업 증명서, 주민등록증에 준하는 신분증
        및 인증사항, 경력사항 등의 제출을 요구할 수 있습니다.
      </p>

      <p>
        나. 제 1항과 같은 목적으로 당사의 사이트에서 튜터에게 온라인상 본인인증 절차를 밟도록 할 수 있으며, 튜터는 이에
        응해야 합니다.
      </p>

      <p>다. 당사는 다음과 같은 회원의 자격을 예고 없이 박탈할 수 있습니다.</p>

      <p>
        <b>(1) 회원의 개인신상정보가 사실과 다를 경우</b>
      </p>

      <p>
        <b>(2) 가입된 정보와 실제 이용자가 다를 경우</b>
      </p>

      <p>
        <b>(3) 사이트에서 취득한 회원정보를 양도하거나 중개에 이용할 경우</b>
      </p>

      <p>
        <b>(4) 본 약관을 준수하지 않아 3회의 경고조치 이후에도 시정 되지 않을 경우</b>
      </p>

      <p>① 1회 약관 위반에 대한 통보 및 시정조치 알림</p>

      <p>② 2회 경고 조치 알림</p>

      <p>③ 3회 회사 측과 유선, 대면 상담 이후 회원 자격 유지 여부 결정</p>

      <p>
        <b>(5) 외부거래 및 결제 부정행위 (직거래 행위)</b>
      </p>

      <p>
        ① 회원이 상호간 아이고고 서비스 내 제공하는 결제 서비스를 이용하지 않고, 다른 방식의 결제를 유도, 실제 거래하는
        행위 일체 (연락처, 소셜계정, 이메일, 메신저, 블로그, 유튜브채널, 각종 URL활용 등을 통한 우회 거래 시도 및 행위
        포함)
      </p>

      <p>② 회원 상호간 기타 혜택 제안 등을 통해 외부 거래를 유도하는 행위</p>

      <p>③ 부모님 및 아이 본인이 아닌 타인 명의를 도용하여 결제하는 행위</p>

      <p>④ 위의 각 호의 행위에 준하는 일체의 결제 부정행위</p>

      <p>
        <b>
          (6) 환불 기간이 끝난 이후 튜터/부모님 및 아이의 개인적인 사유로 생기는 환불 분쟁의 경우, 아이고고 측에서는
          별도의 개입과 판단 대신 약관에 명시된 환불 규정 원칙을 적용
        </b>
      </p>

      <p>
        라. 아이고고에서 자체 제작한 모든 컨텐츠(사진, 영상, 웹, 뉴스 등)의 본 저작권은 제작자인 아이고고에게 있으며,
        제작한 컨텐츠들은 아이고고의 서면동의 없이 무단배포 및 수정을 금지합니다. 허가 되지 않은 행위는 저작권 침해로
        간주되며 아이고고에서 엄격한 법적 조치를 취할 수 있습니다.
      </p>

      <p>
        <b>제16조 (부모님 및 아이의 권리)</b>
      </p>

      <p>
        가. 당사는 본 약관 및 관계법령에 따라 본 서비스를 실시하여야 하며, 다음 각 호의 사유가 발생한 경우를 제외하고
        계속적, 안정적으로 서비스를 제공하기 위해 최선의 노력을 다합니다.
      </p>

      <p>
        <b>① 서비스용 설비의 보수, 정기점검 또는 공사로 인한 부득이한 경우</b>
      </p>

      <p>
        <b>② 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지한 경우</b>
      </p>

      <p>
        <b>③ 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우</b>
      </p>

      <p>
        <b>④ 설비장애 또는 이용 폭주 등으로 인하여 서비스 이용에 지장이 있는 경우</b>
      </p>

      <p>
        나. 당사는 회원의 정보를 철저히 보안 유지하며, 서비스를 운영하거나 개선하는 용도로만 사용하고, 이외의 목적으로
        타 기관 및 개인에게 제공하지 않습니다. 단, 다음 각 호에 해당하는 경우에는 그렇지 않습니다.
      </p>

      <p>
        <b>① 관계법령에 의해 수사상의 목적으로 관계기관으로부터 요청이 있는 경우</b>
      </p>

      <p>
        <b>② 정보통신윤리위원회의 요청이 있는 경우</b>
      </p>

      <p>
        <b>③ 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우</b>
      </p>

      <p>
        <b>제17조 (수강료 결제)</b>
      </p>

      <p>
        가. 부모님 및 아이는 아이고고의 안전결제서비스에 따라 수강하고자 하는 클래스의 수강료를 결제하여야 하고, 회사는
        입금정보와 환불정보를 회사에서 제공하는 양식에 따라 이용자들에게 내역을 제공합니다. 실시간 계좌이체 및 가상계좌
        입금방법으로 대금을 입금하는 경우, 결제금액의 오입금으로 인한 모든 위험과 책임은 부모님 및 아이가 부담하여야
        하며 회사는 이에 대해 책임을 지지 않습니다.
      </p>

      <p>
        나. 부모님 및 아이는 신용카드로 대금을 결제할 수 있습니다. 단, 해외카드를 이용한 결제는 불가능합니다. 회사는
        부모님 및 아이가 제공한 회원정보가 신용카드의 명의인(명의자)와 다른 경우 신용카드 결제를 거부하거나 취소할 수
        있습니다. 다만, 법인카드의 경우 법인인증 확인절차를 거쳐 사용할 수 있습니다.
      </p>

      <p>
        다. 부모 및 아이와 스케줄 상담 중 클래스 시간, 횟수 등을 늘려 추가 금액이 발생할 경우 튜터는 변경된 클래스
        내용을 신규 클래스로 등록해 수강료를 결제하도록 해야하며, 해당 내용을 회사 그리고 부모님 및 아이에게
        고지해야합니다.
      </p>

      <p>
        라. 부모님 및 아이는 결제금액의 전부 또는 일부를 보유하고 있는 아이고고 포인트 또는 쿠폰을 사용하여 결제할 수
        있습니다. 이 경우, 수강증 확인화면의 포인트 사용란에서 사용할 포인트 결제액을 기입 또는 쿠폰을 선택해 차액만큼만
        허용된 결제방법으로 결제하면 됩니다.
      </p>

      <p>
        <b>제18조 (구매확정 및 수익금)</b>
      </p>

      <p>
        가. (정규 클래스) 부모님 및 아이가 1회차 클래스를 가져본 후, 수정/ 취소 요청을 하지않고 클래스를 지속하는 경우
        결제대금은 아이고고에 귀속됩니다. 단, 첫 클래스를 시작하기 24시간 이전에 취소요청을 하는 경우에는 결제대금이
        100% 환불됩니다. 만일 첫 클래스 시작까지 24시간 이내에 취소 의사를 밝히거나 첫 클래스 진행 후 환불을
        요청하더라도 수강료 중 50% 금액은 환불 받을 수 없으며, 스케줄 조정 및 활용 명목으로 튜터에게 귀속됩니다.
      </p>

      <p>
        나. (원데이 클래스) 부모님 및 아이가 해당 클래스를 완전히 수강한 경우 또는 취소 의사를 밝히지 않고 예정된 클래스
        일정이 지난 경우, 결제 금액은 아이고고로 귀속됩니다. 단, 클래스를 시작하기 24시간 이전에 취소요청을 하는
        경우에는 결제대금이 100% 환불됩니다. 만일 24시간 이내에 취소 의사를 밝히는 경우에는 환불이 불가하며 해당 금액은
        스케줄 조정 및 활용 명목으로 튜터에게 귀속됩니다.
      </p>

      <p>
        다. 부모님 및 아이가 클래스 수강료를 결제하는 경우, 요청한 시각으로부터 향후 12시간 이내에 카드로 결제하여야
        합니다. 2회차 이상의 클래스를 진행한 이후 환불을 요청할 경우 수강료는 환불되지 않습니다.
      </p>

      <p>
        회사는 거래 내역, 수수료 세금계산서 발행내역, 수익금 출금내역 요청 시 이를 요청자에게 공개합니다. 다만, 회사는
        이 약관 [제11조 '마' 항의 클래스 등록양식에 어긋난 등록, 허위등록, 판매가장등록, 신용카드결제시스템 또는 통신
        과금 시스템만을 이용하기 위한 클래스 등록 여부]를 확인하기 위하여 최고 60일까지 출금을 보류할 수 있습니다. 이
        경우, 튜터가 클래스에 관한 거래사실 증빙서류를 회사에 제공하는 때에는 확인 후 송금처리를 합니다.
      </p>

      <p>
        라. 튜터는 클래스 등록 시 회사 및 부모님 및 아이로부터 수익금을 수취할 계좌를 등록하여야 하며, 회사가 정하는
        기준 내에서 계좌를 변경할 수 있습니다. 튜터가 지정한 입금계좌의 예금주는 튜터와 동일인(개인회원의 경우 가입자
        본인 명의)임을 원칙으로 합니다. 튜터가 지정한 입금계좌의 예금주가 튜터 가입정보와 상이한 경우 송금에 불이익을
        받을 수 있으며, 해당 조건에 맞는 계좌 관련 서류증빙이 완료될 때까지 대금의 송금을 보류할 수 있습니다.
      </p>

      <p>
        마. 첫 클래스 진행 이전 취소 가능 기간은 60일입니다. 부모님 및 아이가 입금한 날짜로부터 60일 이내에 환불, 클래스
        교환 등의 의사를 표시하지 않고 클래스의 진행여부를 최종 확정을 지연하는 경우 61일째 되는 날 자동 최종확정 처리가
        되어 튜터의 계좌로 수익금이 예치됩니다. 다만, 튜터와 부모님 및 아이의 요청 시(긴급한 개인적 용무 등) 최종확정
        기간은 연장될 수 있습니다.
      </p>

      <p>바. 튜터는 회사에 대한 클래스대금채권이 있을 경우 타인에게 양도할 수 없습니다.</p>

      <p>
        <b>제19조 (환불)</b>
      </p>

      <p>
        <b>1. 정규클래스(2회차 이상의 클래스)</b>
      </p>

      <p>
        가. 첫 클래스를 시작하기 24시간 이전에 취소요청을 하는 경우에는 결제대금이 100%환불됩니다. 단, 튜터에게 먼저
        어플 내 채팅 기능으로 취소, 환불 의사를 통보한 후에 환불 접수가 가능합니다.
      </p>

      <p>
        나. 첫 클래스 시작 24시간 이내에 취소 의사를 밝히거나 첫 클래스 진행 후 환불을 요청하더라도 전체 수강료 중 50%에
        해당하는 금액은 환불 받을 수 없으며, 시범강의 및 장소대여, 참여인원 미확보, 향후 스케줄 조정에 대한 명목으로
        튜터에게 귀속됩니다.
      </p>

      <p>
        다. 튜터와 부모님 및 아이 양측은 정규 클래스 중 1회 클래스 진행 후 클래스를 지속하기로 결정한 이후임에도, 부모님
        및 아이의 사정으로 클래스를 중단해야 하는 경우에 한해 다음과 같은 환불 규정을 적용합니다.
      </p>

      <p>
        ① 튜터는 전체 등록 커리큘럼의 50%에 해당하는 시간을 진행하기 이전에는, 진행된 클래스 기간을 제외한 나머지 기간에
        대한 금액을 부모님 및 아이에게 환불하여야 합니다. (아이고고에서 환불 수행)
      </p>

      <p>
        ② 튜터는 전체 등록 커리큘럼의 50%에 해당하는 시간을 진행한 경우에는, 진행되지 않은 잔여 기간과 상관없이 환불의
        의무를 지지 않습니다. (아이고고 서비스에 대금귀속)
      </p>

      <p>
        ③ 부모님 및 아이가 튜터에게 문제가 있다고 판단하여 클래스 진행 및 환불을 요청하는 경우, 아이고고 측에서는 개입할
        수 있으며 이에 대한 사유 확인과 클래스 과정상에 문제는 없었는지 여부를 확인합니다. 즉, 클래스 진행상의 불쾌감
        조성, 준비 소홀, 협박, 폭행, 추행, 불법적인 회유 등의 튜터의 의무에 맞지 않는 행위를 확인하여, 해당 사실이
        발견될 시에는 형사 고발 및 법적인 조치를 가할 수 있습니다. 또한, 튜터의 회원자격을 정지 또는 서비스 이용을
        제한하는 조치를 취할 수 있습니다.
      </p>

      <p>
        ④ 약관에 규정된 회사의 아이고고 안전결제 서비스가 종료된 후, 클래스의 환불 등과 관련하여 튜터와 부모님 및 아이
        사이에 분쟁 등이 발생한 경우 원칙상 관련 당사자간에 해결을 우선시하며, 상호 합의 이후 회사는 이에 대한 책임을
        보증하지 않습니다.
      </p>

      <p>라. 튜터 개인의 사정으로 인해 클래스를 중단해야 하는 경우는 다음과 같습니다.</p>

      <p>
        ① 커리큘럼이 남아 있음에도 불구하고, 클래스를 중단하는 경우 그 시기와 상관없이 튜터는 수강료로 받은 모든 금액을
        부모님 및 아이에게 환불하여야 합니다. (아이고고에서 환불 수행)
      </p>

      <p>② 결제 수수료는 환불되지 않습니다.</p>

      <p>③ 튜터의 개인 사정은 건강상의 이유, 취직, 외국으로의 유학 등 모든 사항에 대해 예외를 두지 않습니다.</p>

      <p>
        ④ 단, 튜터가 부모님 및 아이에게 문제가 있다고 판단하여 클래스 진행 및 환불을 거부하는 경우, 아이고고 측에서는
        개입할 수 있으며 이에 대한 사유 확인과 클래스 과정상의 문제는 없었는지 여부를 확인합니다. 즉, 클래스 진행 상의
        불쾌감 조성, 협박, 폭행, 추행, 불법적인 회유 등의 범죄에 준하는 행위를 확인하여, 해당 사실이 발견되는 즉시 형사
        고발 및 법적인 조치를 가할 수 있습니다. 또한, 부모님 및 아이의 회원자격을 정지 또는 서비스 이용을 제한하는
        조치를 취할 수 있습니다.
      </p>

      <p>
        ⑤ 튜터와 부모님 및 아이간의 협의 하에 부모님 및 아이의 남은 클래스 분 수강료만 튜터에게 환불 조치 하는 것은
        가능하며, 이에 대해 아이고고 측에서는 환불집행에만 관여합니다. 튜터의 귀책 사유로 다수의 부모님 및 아이의 취소
        및 환불 요청이 발생하는 경우, 또는 동일 중분류 카테고리 내 타 클래스보다 환불요청이 현저히 높은 경우 회사는
        튜터의 등록 제한, 인기도 감소 등의 페널티를 부과할 수 있습니다.
      </p>

      <p>
        마. 튜터와 부모님 및 아이가 서비스를 이용하여 약속된 첫 클래스 일정을 진행한 이후, 어플을 통해 사전 약속되지
        않은 튜터와 부모님 및 아이의 상호 추가적 협의에 대한 금전적 환불에 대해서 아이고고는 관여하지 않습니다. 단,
        튜터와 부모님 및 아이 사이에 분쟁이 발생한 경우 아이고고측에서는 개입할 수 있으며, 이를 중재하고 협의 및
        권장조치를 취할 수 있습니다.
      </p>

      <p>
        <b>2. 원데이 클래스의 경우</b>
      </p>

      <p>
        가. 클래스를 시작하기 '24시간 이전'에 취소요청을 하는 경우에는 결제대금이 100%환불됩니다. 튜터에게 먼저 어플 내
        채팅 기능으로 취소, 환불 의사를 통보한 후에 환불 접수가 가능합니다.
      </p>

      <p>
        단, 클래스 시작 시간을 기준으로 '24시간 이내'에 취소 의사를 밝히는 경우에는 수강료 환불이 불가하며<b>, </b>
        해당 금액은 시범강의 및 장소대여, 참여인원 미확보, 향후 스케줄 조정에 대한 명목으로 튜터에게 귀속됩니다.
      </p>

      <p>나. 클래스를 수강했거나 수강하기로 예정된 클래스 일정이 지난 경우, 결제 금액은 전액 환불되지 않습니다.</p>

      <p>
        다. 클래스 등록 페이지 및 상호 합의/조정한 커리큘럼 전체의 클래스를 튜터가 이행한 경우, 다음과 같은 환불 규정을
        적용 합니다.
      </p>

      <p>① 튜터는 커리큘럼 상의 클래스를 이행 했으므로, 환불이 불가능합니다.</p>

      <p>
        ② 부모님 및 아이가 튜터에게 문제가 있다고 판단하여 클래스 진행 및 환불을 요청하는 경우, 아이고고 측에서는 개입할
        수 있으며 이에 대한 사유 확인과 클래스 과정상에 문제는 없었는지 여부를 확인합니다. 즉, 클래스 진행상의 불쾌감
        조성, 준비 소홀, 협박, 폭행, 추행, 불법적인 회유 등의 튜터의 의무에 맞지 않는 행위를 확인하여, 해당 사실이
        발견될 시에는 형사 고발 및 법적인 조치를 가할 수 있습니다. 또한, 튜터의 회원자격을 정지 또는 서비스 이용을
        제한하는 조치를 취할 수 있습니다.
      </p>

      <p>
        ③ 약관에 규정된 회사의 아이고고 안전결제 서비스가 종료된 후, 클래스의 환불 등과 관련하여 튜터와 부모님 및 아이
        사이에 분쟁 등이 발생한 경우 원칙상 관련 당사자간에 해결을 우선시하며, 상호 합의 이후 회사는 이에 대한 책임을
        보증하지 않습니다.
      </p>

      <p>
        라. 튜터와 부모님 및 아이가 서비스를 이용하여 약속된 첫 클래스 일정을 진행한 이후, 어플을 통해 사전 약속되지
        않은 튜터와 부모님 및 아이의 상호 추가적 협의에 대한 금전적 환불에 대해서 아이고고는 관여하지 않습니다. 단,
        튜터와 부모님 및 아이 사이에 분쟁이 발생한 경우 아이고고측에서는 개입할 수 있으며, 이를 중재하고 협의 및
        권장조치를 취할 수 있습니다.
      </p>

      <p>
        마. 튜터 전자책의 경우, 튜터가 실물을 부모님 및 아이에게 발송한 이후에는 환불이 불가능 합니다. (단, 실물이 소개
        페이지 내용과 다른 경우 아이고고에서 확인하여 환불을 진행할 수 있습니다.)
      </p>

      <p>
        <b>제20조 (면책조항) </b>
      </p>

      <p>
        가. 당사는 부모님 및 아이와 튜터가 독립적이고 자발적인 의사에 따라 클래스를 신청, 협의, 결정할 수 있도록 사전에
        동의한 정보와 방식으로 중개업무를 하고 있습니다. 아이고고는 회원이 등록한 정보에 대한 사실의 정확성과 신뢰성을
        확보하기 위해 학력 인증, 신분증 인증, 경력 인증에 대해 정확히 인증 자료를 확보하고 이를 서비스 내에 표시합니다.
        이에 대한 허위 정보 보증은 아이고고에게 책임이 있으며, 아이고고가 회원의 허위 신분증과 자격증을 보증한 경우
        아이고고의 귀책으로 간주합니다. 다만 국가공인 자격증과 같이 명확히 시기와 보증번호 등을 확인할 수 없는 인성,
        업무 경험 등과 같은 정성적인 정보에 대한 보증은 하지 않습니다. 따라서 이로 인해 발생하는 정신적인 피해 및 손해
        등에 대해서는 책임을 지지 않습니다.
      </p>

      <p>
        나. 당사는 클래스 방식, 결제 방식, 환불 규정들은 아이고고의 각 약관 규정들을 따르나, 부모님 및 아이, 튜터와의
        상호간 협의 사항이 있는 경우에는 규정이 면칙 될 수 있도록 우선순위를 두고 있습니다. 또한 약관과 다르게 클래스를
        진행한다는 상호 협의가 있는 경우, 이로 인해 발생하는 분쟁과 관련한 책임은 당사자들이 지며, 당사는 당사의 고의
        또는 중과실이 없는 한 책임이 없습니다.
      </p>

      <p>
        다. 당사는 천재지변 또는 이에 준하는 불가항력으로 인해 서비스를 제공할 수 없는 경우 서비스 제공에 관한 책임이
        면제됩니다. 또한, 서비스 제공의 지연이나 서비스를 제공하지 못하거나 또는 고객정보가 유출된 경우 당사의 고의 또는
        과실이 없는 한 책임을 지지 않습니다.
      </p>

      <p>
        라. 당사는 이용자의 귀책사유로 인해 서비스 이용의 장애가 발생한 경우, 당사의 고의 또는 중과실이 없는 한 책임이
        면제됩니다.
      </p>

      <p>
        마. 이용자가 본 약관의 규정을 위반함으로 인해 회사에 손해가 발생하는 경우, 이 약관을 위반한 이용자는 회사에
        발생되는 손해를 배상할 책임이 있습니다. 또한 회사가 약관을 위반한 이용자로 인해 제3자에게 손해를 배상한 경우,
        관련 비용에 대한 배상을 청구 할 수 있습니다.
      </p>

      <p>
        <b>제21조 (회원자격의 박탈)</b>
      </p>

      <p>가. 다음 각 호에 해당하는 경우 사전 통보 없이 강제탈퇴 하거나, 이용을 중지할 수 있습니다.</p>

      <p>
        <b>① 공공질서 및 미풍양속에 반하는 경우</b>
      </p>

      <p>
        <b>② 범죄적 행위에 관련되는 경우</b>
      </p>

      <p>
        <b>③ 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행할 경우</b>
      </p>

      <p>
        <b>④ 타인의 계정 및 비밀번호를 도용한 경우</b>
      </p>

      <p>
        <b>⑤ 타인의 명예를 손상시키거나 불이익을 주는 경우</b>
      </p>

      <p>
        <b>⑥ 같은 사용자가 다른 계정으로 이중 등록을 한 경우</b>
      </p>

      <p>
        <b>⑦ 회사 직원 및 운영자 등을 포함한 타인을 사칭하기 위해 등록을 한 경우</b>
      </p>

      <p>
        <b>⑧ 서비스에 위해를 가하는 등 건전한 이용을 저해하는 경우</b>
      </p>

      <p>
        <b>⑨ 기타 관련 법령이나 회사가 정한 이용조건에 위배되는 경우</b>
      </p>

      <p>
        나. 튜터가 클래스를 이행하지 않고 일방적으로 계약을 파기하고 부모님 및 아이에게 수강료를 환불하지 않을 경우,
        당사는 해당 튜터의 회원자격을 박탈함과 동시에 관계법령에 의해 튜터를 고소, 고발하는 등의 조치를 취할 수 있으며,
        피해자가 고소, 고발 조치를 취한 경우 당사는 적극 협조합니다.
      </p>

      <p>
        다. 아이고고 웹사이트 및 모바일 앱은 부모님 및 아이와 튜터 간의 클래스 연결 및 아이고고의 서비스를 제공받기 위한
        목적으로만 이용이 가능하며, 사교육 기관이 부모님 및 아이를 모집하거나 광고 등에 상업적으로 이용할 경우 예고 없이
        회원자격을 박탈합니다.
      </p>

      <p>
        라. 타인에게 계정을 공유하거나, 기타 상업 및 비상업적으로 서비스를 이용할 시 회원등록 취소 및 강제탈퇴 처리되며,
        당사의 운영상 영업방해 및 이미지 훼손에 대한 손해배상과 개인정보 무단수집에 대한 모든 민/형사적 책임을 집니다.
      </p>

      <p>
        <b>제22조 (분쟁의 해결)</b>
      </p>

      <p>가. 당사와 이용자는 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위해 서로 필요한 노력을 해야 합니다.</p>

      <p>
        나. 제 1항의 규정에도 불구하고, 동 분쟁으로 인해 소송이 제기될 경우 소송은 상호 합의 하에 정한 소재 법원으로
        합니다. 상호 합의점이 도출되지 않는 경우, 민사상에서 정하는 관할 법원으로 합니다.
      </p>

      <p>
        <b>부칙</b>
      </p>

      <p>
        이용약관 내용 추가, 삭제 및 수정이 있을 시에는 개정일로부터 7일 전, 회원의 권리 의무에 중대한 영향을 미치는
        중요한 사항의 변경인 경우에는 30일 전에 아이고고 웹사이트 공지사항, 이메일 등을 통해 고지합니다.
      </p>

      <p>
        <b>공고일자 : 2021년 01월 21일</b>
      </p>

      <p>
        <b>시행일자 : 2021년 01월 28일</b>
      </p>
    </div>
  );
};

export const TermsPage: React.FC = () => {
  return (
    <div className="privacy-page" style={{ width: '100vw', height: '100vh', overflowY: 'auto', overflowX: 'hidden' }}>
      <div className="container">
        <TermsContent />
      </div>
    </div>
  );
};

export default TermsPage;
