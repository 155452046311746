import { create } from 'zustand';

interface AppMarketingModalStore {
  showMarketingModal: boolean;
  setShowMarketingModal: (showMarketingModal: boolean) => void;
  close: () => boolean;
}

export const useAppMarketingModalStore = create<AppMarketingModalStore>((set, get) => ({
  showMarketingModal: true,
  setShowMarketingModal: (showMarketingModal: boolean) => {
    set({ showMarketingModal });
  },
  close: () => {
    const isCloseExecute = !!get().showMarketingModal;
    set({ showMarketingModal: false });
    return isCloseExecute;
  },
}));
