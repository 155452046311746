import { useAppBottomSheetStore } from '@stores/appBottomSheet';
import { motion } from 'framer-motion';
import React from 'react';

export const AppBottomSheet: React.FC = () => {
  const appBottomSheetStore = useAppBottomSheetStore();
  const show = appBottomSheetStore.show;

  const showAnimation = {
    opacity: 1,
    display: 'block',
  };

  const hideAnaimation = {
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
  };

  return (
    <div id="bottom-sheet" style={{ height: show ? 'auto' : 0 }}>
      <motion.div
        animate={show ? showAnimation : hideAnaimation}
        transition={{ duration: 0.5 }}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.preventDefault();
          e.stopPropagation();
          appBottomSheetStore.close();
        }}
        style={{
          opacity: 0,
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.4)',
          zIndex: show ? 99999 : -10,
        }}></motion.div>
      <motion.div
        animate={{ bottom: show ? 0 : -appBottomSheetStore.options.height, opacity: show ? 1 : 0 }}
        transition={{ delay: 0.2, duration: 0.3 }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{
          opacity: 0,
          position: 'absolute',
          height: appBottomSheetStore.options.height,
          backgroundColor: 'white',
          width: '100%',
          zIndex: show ? 999999 : -10,
          borderRadius: '20px 20px 0 0',
          overflow: 'hidden',
        }}>
        {appBottomSheetStore.options?.elements}
      </motion.div>
    </div>
  );
};

export default AppBottomSheet;
