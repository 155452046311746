import React from 'react';

export const SelectTossPaymentMethod: React.FC = () => {
  return (
    <div
      style={{
        marginTop: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
        marginBottom: '24px',
      }}>
      <div id="toss-payment-widget"></div>
      {/* <div
        style={{
          display: 'flex',
          height: '44px',
          marginBottom: '32px',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f5f7fb',
          borderRadius: '8px',
        }}>
        <div
          style={{
            color: 'var(--Neutral-70, #808387)',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
          }}>
          계좌이체 : 카카오톡 '아이고고' 고객센터 문의
        </div>
      </div> */}
    </div>
  );
};

export default SelectTossPaymentMethod;
