import * as tutorProductsApi from '@apis/tutor-products';
import { ClassInvoiceSummary } from '@components/app/class-invoice/ClassInvoiceSummary';
import BottomRoundButton from '@components/common/BottomRoundButton';
import { CreateClassInvoiceData } from '@models/invoice';
import { ClassSchedule } from '@models/schedule';
import { useClassInvoiceModalStore } from '@stores/classInvoiceModalStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useMutation } from '@tanstack/react-query';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React from 'react';
import { toast } from 'react-toastify';
import ClassFeeView from '../../class-invoice/ClassFeeView';
import Divider from '../../class-invoice/Divider';

export interface ClassInvoiceConfirmProps {
  data?: CreateClassInvoiceData;
  onCancel?: () => void;
  onConfirm?: (data: any) => void;
}

export const ClassInvoiceConfirm: React.FC<ClassInvoiceConfirmProps> = (props) => {
  const createData = props.data;
  const classInvoiceModal = useClassInvoiceModalStore();
  const dialog = useOkCancelDialog();

  const createClassInvoiceMutation = useMutation({
    mutationFn: tutorProductsApi.createClassInvoice,
    onSuccess: async () => {
      classInvoiceModal.close();
      toast.dark('수강증 발행이 완료됐습니다.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        bodyStyle: {
          color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
        },
        theme: 'dark',
      });
    },
    onError: async (e) => {
      dialog.open({ type: 'ok', title: '수강증 발행 오류', content: e });
    },
  });

  if (!createData) {
    return <div></div>;
  }

  return (
    <OverlayScrollbarsComponent
      defer
      options={{
        scrollbars: { autoHide: 'scroll' },
        overflow: {
          x: 'hidden',
        },
      }}
      style={{
        backgroundColor: '#ffffff',
        marginTop: 0,
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        width: '100%',
      }}>
      <div style={{ padding: '20px' }}>
        <div
          style={{
            color: 'var(--Neutral-100, #242424)',
            fontFamily: 'Pretendard',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
            letterSpacing: '0.2px',
          }}>
          수강증을 발행하시겠어요?
        </div>
        <div
          style={{
            marginTop: '8px',
            color: 'var(--Neutral-70, #808387)',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
          }}>
          수강증 발행 후에는 일정 변경만 가능합니다.
        </div>
      </div>
      <div style={{ padding: '20px 16px 28px 16px' }}>
        <ClassInvoiceSummary
          isTutor={false}
          tutorName={createData.tutorName!}
          classTitle={createData.classTitle!}
          classThumbnail={createData.classThumbnail!}
          tutorProfileImage={createData.tutorProfileImage}
          students={createData.students!}
          additionalParticipantCount={createData.additionalParticipantCount!}
          totalClassTimes={createData.totalClassTimes!}
          classDuration={createData.classDuration!}
          schedules={(createData.dates || []).map((item) => {
            return { startDate: item.toString() } as Partial<ClassSchedule>;
          })}
        />
      </div>
      <Divider />
      <ClassFeeView
        defaultClassFee={createData.classFeePerTime}
        totalClassTimes={createData.totalClassTimes}
        totalStudentCount={(createData.students || []).length + (createData.additionalParticipantCount || 0)}
        applyGroupDiscount={createData.applyGroupDiscount}
      />
      <div
        style={{
          width: '100%',
          padding: '16px',
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
        }}>
        <BottomRoundButton
          theme="secondary"
          style={{ flex: 1 }}
          onClick={() => {
            props.onCancel && props.onCancel();
          }}>
          돌아가기
        </BottomRoundButton>
        <BottomRoundButton
          style={{ flex: 2, marginLeft: '8px' }}
          onClick={() => {
            createClassInvoiceMutation.mutate(createData);
          }}>
          발행하기
        </BottomRoundButton>
      </div>
    </OverlayScrollbarsComponent>
  );
};
