import { User } from './auth';
import { ClassProduct, SpecificAddress } from './class';
import { CommerceProduct } from './commerce';
import { UserMembership } from './common';
import { ClassInvoice } from './invoice';
import { ProductModel } from './product';
import { RefundLog } from './refundLog';
import { ReviewContent } from './review';
import { ClassSchedule } from './schedule';

export enum PaymentStatementRefundStatus {
  NONE = 'none',
  ALL = 'all',
  PARTIAL = 'partial',
  REQUESTED = 'requested',
  ACCEPTED = 'accepted',
  DENIED = 'denied',
  RESERVED = 'reserved',
}

export interface IamportResponse {
  imp_success: string;
  imp_uid: string;
  merchant_uid: string;
  amount: number;
  payType: string;
}

export interface ClassPurchase {
  _id: string;
  programId: string;
  requestId: string;
  userId: string;
  response: IamportResponse;
  userPayCount?: number;
  createdAt: Date;
  updatedAt?: Date;
  creditPoints?: number;
  refundCreditPoints?: number;
  refundAmount?: number;
  isRefundRegister?: boolean;
  refundRegisterCount?: number;
  isPaidout?: Date;
}

export interface ClassPurchaseModel extends ClassPurchase {
  classProduct?: ClassProduct;
  invoice?: ClassInvoice;
  review?: ReviewContent;
}

export interface SettlementsModel {
  _id: string;
  purchasedUserName?: string;
  classTitle?: string;
  purchasedFee?: number;
  invoiceState?: string;
  scheduleTimes?: string;
  settlementFee?: number;
  reportPushDate?: string;
  createdAt?: string;
  transferAt?: string;
}

export interface ProductOptionList {
  optionName: string | string[];
  selectOption?: string[];
  optionFee: number;
  optionCount: number;
}

export interface RefundList {
  optionName: string | string[];
  selectOption?: string[];
  optionFee: number;
  optionCount: number;
  refundAmount?: number;
  essential?: boolean;
}
export interface CommerceRefundList {
  refundList: RefundList[];
  refundReciptUrl: string;
  amount: number;
  creditPoints: number;
  createdAt: Date;
}

export interface CommercePurchase {
  _id: string;
  productId: string;
  userId: string;
  response: IamportResponse;
  productOptionList: ProductOptionList[];
  productShippingFee: number;
  productSaleFee: number;
  productState: string;
  buyerName: string;
  buyerPhone: string;
  shippingName: string;
  shippingPhone: string;
  shippingMemo?: string;
  shippingAddress?: SpecificAddress;
  payType: string;
  creditPoints: number;
  createdAt: Date;
  shippingId: string;
  shippingNumber: string;
  shippingInfoName: string;
  updatedAt: string;
  shippingStatus: string;
  shippingStatusName: string;
  productName: string;
  userName: string;
  sellerName: string;
  refundMemo?: string;
  refundAmount?: number;
  refundCreditPoints?: number;
  refundState?: string;
  isRefundShippingFee?: boolean;
  refundList?: CommerceRefundList[];
  refundRegisterCount?: number;
}

export interface CommercePurchaseModel extends CommercePurchase {
  commerceProduct?: CommerceProduct;
}

export interface GetPurchasesQuery {
  startDate?: Date;
  endDate?: Date;
  searchText?: string;
  paymentStatus?: string;
  dateType?: string;
  page?: number;
  size?: number;
  withoutAmount?: boolean;
}

export interface CreatePaymentStatementsBody {
  uuid: string;
  buyer: PaymentStatementUser;
  seller: PaymentStatementUser;
  product: PaymentStatementProduct;
  purchases: PaymentStatementPurchase[];
  payment: PaymentStatementPayment;
  statuses: PaymentStatementStatus;
  shipping?: PaymentStatementShipping;
  memo?: PaymentStatementMemo;
}

export enum PaymentStatementReportStatus {
  PUBLISHED = 'published',
  EDITTING = 'editting',
}

export enum PaymentStatementPaymentStatus {
  DONE = 'done',
  READY = 'ready',
  CANCEL = 'cancel',
  ERROR = 'error',
  WAITING_FOR_DEPOSIT = 'waiting_for_deposit',
  IN_PROGRESS = 'in_progress',
}

export interface PaymentStatementStatus {
  payment?: 'DONE' | 'READY' | 'NONE' | 'CANCEL' | 'ERROR' | PaymentStatementPaymentStatus;
  refund?: 'NONE' | 'refundRegister' | PaymentStatementRefundStatus;
  settlement?: 'DONE' | 'NONE' | 'READY' | 'done' | 'none' | 'ready';
  shipping?: string;
  report?: PaymentStatementReportStatus;
}

export interface PaymentStatementUser extends Partial<User> {
  id: string;
  name?: string;
  phone?: string;
  membership?: Partial<UserMembership>;
}

export interface PaymentStatementProduct extends Partial<ProductModel> {
  saleType: string;
  id: string;
  name: string;
  classInvoiceId?: string;
  applyGroupDiscount?: boolean;
  option?: { optionSelectType?: string; useRequiredPurchaseQuantityOptions?: boolean };
}

export interface PaymentStatementShipping {
  name?: string;
  phone?: string;
  fee?: number;
  address?: any;
  addressDetail?: string;
  invoiceNumber?: string;
  company?: string;
  status?: string;
}

export interface PaymentStatementPurchase {
  option?: string[];
  name: string | string[];
  fee?: number;
  count: number;
}

export interface PaymentStatementPayment {
  method: string;
  gateway: string;
  amount: number;
  point: number;
  billingAmount: number;
  membershipDiscountAmount?: number;
  paidAt?: Date;
  result?: any;
  error?: any;
}

export interface refundItem {
  option?: string[];
  name?: string | string[];
  fee: number;
  count: number;
}

export enum ReserveStatus {
  RESERVE = 'reserve',
  DONE = 'done',
  CANCELED = 'canceled',
  NONE = 'none',
  REQUESTED = 'requested',
}

export enum RefundSubmissionStatus {
  NOTIFIED = 'notified',
  NONE = 'none',
}

export interface RefundData {
  receiptUrl?: string;
  createdAt?: Date;
  amount?: number;
  billingAmount?: number;
  point?: number;
  refundItems?: PaymentStatementPurchase[];
  status?: ReserveStatus;
  submissionStatus?: RefundSubmissionStatus;
}

export interface PaymentStatementRefund {
  amount?: number;
  point?: number;
  billingAmount?: number;
  data: (RefundData | RefundLog)[];
  legacyHistory?: RefundData[];
  noMoreRefundsAvailable?: boolean;
  submissionStatus?: RefundSubmissionStatus;
}

export interface PaymentStatementSettlement {
  targetAmount: number;
  ratio: number;
  amount: number;
  proceedAmount: number;
}

export interface PaymentStatementMemo {
  payment?: string;
  refund?: string;
  refundUser?: string;
  refundPartner?: string;
  settlement?: string;
  shipping?: string;
  qna?: string;
}

export interface ReviewModel {
  _id: string;
  userId: string;
  comment: string;
  rootId: string;
  productId: string;
  paymentStatementId: string;
  rates: Rates;
  step: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface Rates {
  rate1: number;
  rate2: number;
  rate3: number;
  rate4: number;
}

export interface PaymentStatementLog {
  _id: string;
  statementUuid: string;
  event: string;
  data: { [key: string]: any };
  createdAt: Date;
}

export interface PaymentStatement {
  _id?: string;
  uuid: string;
  buyer: PaymentStatementUser;
  firstPlannedDates?: Date;
  productDetail?: ProductModel[];
  seller: PaymentStatementUser;
  product: PaymentStatementProduct;
  purchases: PaymentStatementPurchase[];
  refunds?: PaymentStatementPurchase[];
  payment: PaymentStatementPayment;
  refund?: PaymentStatementRefund;
  shipping?: PaymentStatementShipping;
  settlement?: PaymentStatementSettlement;
  statuses: PaymentStatementStatus;
  memo?: PaymentStatementMemo;
  legacy?: { [key: string]: any };
  review?: ReviewModel;
  createdAt?: Date;
  updateddAt?: Date;
  error?: { message: string; productId: string };
  plannedDates?: Date[];
  classSchedule?: ClassSchedule;
}

export interface PaymentStatementWithLogs extends PaymentStatement {
  statementLogs?: PaymentStatementLog[];
}

export interface TossPaymentsConfirmResponseBody {
  createdAt: string;
  eventType: string;
  data: TossPaymentsConfirmResponseData;
}

export interface TossPaymentsConfirmResponseData {
  mId: string;
  lastTransactionKey: string;
  paymentKey: string;
  orderId: string;
  orderName: string;
  taxExemptionAmount: number;
  status: string;
  requestedAt: string;
  approvedAt: string;
  useEscrow: boolean;
  cultureExpense: boolean;
  card: Card;
  virtualAccount: any;
  transfer: any;
  mobilePhone: any;
  giftCertificate: any;
  cashReceipt: any;
  cashReceipts: any;
  discount: any;
  cancels: any;
  secret: string;
  type: string;
  easyPay: any;
  country: string;
  failure: any;
  isPartialCancelable: boolean;
  receipt: Receipt;
  checkout: Checkout;
  currency: string;
  totalAmount: number;
  balanceAmount: number;
  suppliedAmount: number;
  vat: number;
  taxFreeAmount: number;
  method: string;
  version: string;
}

export interface Card {
  issuerCode: string;
  acquirerCode: string;
  number: string;
  installmentPlanMonths: number;
  isInterestFree: boolean;
  interestPayer: any;
  approveNo: string;
  useCardPoint: boolean;
  cardType: string;
  ownerType: string;
  acquireStatus: string;
  amount: number;
}

export interface Receipt {
  url: string;
}

export interface Checkout {
  url: string;
}

export interface TossCheckoutParams {
  paymentType?: string | null;
  orderId?: string | null;
  paymentKey?: string | null;
  amount?: string | null;
  code?: string | null;
  message?: string | null;
}

export interface RefundRequestBody {
  type: 'request';
  memo: string;
  refundItems: PaymentStatementPurchase[];
}
export interface RefundCancelBody {
  type: 'cancel';
  cancelRefundLogId: string;
}
export interface ResponseRefundBody {
  referenceId?: string;
  type: 'accepted' | 'denied';
  content?: string;
  fee?: number;
  noMoreRefundsAvailable?: boolean;
  refundItems?: PaymentStatementPurchase[];
}
