import * as authApi from '@apis/auth';
import { getUserDeviceId } from '@utils/string';
import axios from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from '../variables';

export const apiClient = axios.create({
  baseURL: `${API_URL}`,
  withCredentials: true,
  timeout: 600000,
});

apiClient.interceptors.request.use(
  (config) => {
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    }

    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh-token');

    config.headers['authorization'] = `Bearer ${token}`;
    if (config.url?.includes('auth/v1/refresh')) {
      config.headers['x-refresh-token'] = `${refreshToken}`;
    }
    config.headers['x-device-id'] = getUserDeviceId();

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (config) => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh-token');

    const newToken = Cookies.get('Authentication') || '';
    const newRefreshToken = Cookies.get('AuthRefresh') || '';
    if (newToken && token !== newToken) {
      localStorage.setItem('token', newToken);
    }
    if (newRefreshToken && newRefreshToken !== refreshToken) {
      localStorage.setItem('refresh-token', newRefreshToken);
    }

    return config;
  },
  async (error) => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh-token');
    if (
      error.config &&
      !error.config.url.includes('auth/v1/refresh') &&
      error.response &&
      error.response.status === 401 &&
      refreshToken
    ) {
      return authApi
        .refresh()
        .then(() => {
          const newToken = Cookies.get('Authentication') || undefined;
          const newRefreshToken = Cookies.get('AuthRefresh') || undefined;

          if (newToken && token !== newToken) {
            localStorage.setItem('token', newToken);
          }
          if (newRefreshToken && newRefreshToken !== refreshToken) {
            localStorage.setItem('refresh-token', newRefreshToken);
          }

          error.config.headers['authorization'] = `Bearer ${newToken}`;
          if (error.config.url?.includes('auth/v1/refresh')) {
            error.config.headers['x-refresh-token'] = `${newRefreshToken}`;
          }

          return axios.request(error.config);
        })
        .catch((e) => {
          if (e?.response?.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh-token');
            Cookies.remove('Authentication');
            Cookies.remove('AuthRefresh');
          }
          return Promise.reject(e);
        });
    } else if (error?.response?.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh-token');
      localStorage.removeItem('igogo-app-user');
      Cookies.remove('Authentication');
      Cookies.remove('AuthRefresh');
    }
    return Promise.reject(error);
  },
);
