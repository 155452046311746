import { create } from 'zustand';

interface LoginModalOption {
  closeAndGoBack?: boolean;
}
interface AppLoginModal {
  show?: boolean;
  options?: LoginModalOption;
  open: (options?: LoginModalOption) => void;
  close: () => boolean;
}

export const useAppLoginModal = create<AppLoginModal>((set, get) => ({
  show: false,
  open: (options?: LoginModalOption) => {
    set({ show: true, options });
  },
  close: () => {
    const isCloseExecute = !!get().show;
    set({ show: false });
    return isCloseExecute;
  },
}));
