import React from 'react';

export const Divider: React.FC = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '8px',
        backgroundColor: '#EFF1F4',
      }}></div>
  );
};

export default Divider;
