import React from 'react';
const DEFAULT_THEME = { backgroundColor: '#FF3D8F', color: 'white', border: 'none', fontWeight: 700 };
const THEME = {
  primary: { backgroundColor: '#FF3D8F', color: 'white', border: 'none', fontWeight: 700 },
  secondary: { backgroundColor: 'white', color: '#424242', border: '1px solid #D5D8DD', fontWeight: 600 },
  tertiary: { backgroundColor: 'white', color: '#FF3D8F', border: '1px solid #FF3D8F', fontWeight: 500 },
};

export const BottomRoundButton: React.FC<{
  style?: any;
  children?: any;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  currentStep?: number;
  totalStep?: number;
  theme?: 'primary' | 'secondary' | 'tertiary';
}> = (props) => {
  const { color, backgroundColor, border, fontWeight } = (props.theme && THEME[props.theme]) || DEFAULT_THEME;
  return (
    <button
      disabled={props.disabled}
      className="link-button"
      style={{
        width: '100%',
        display: 'flex',
        height: '56px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        backgroundColor: props.disabled ? '#D5D8DD' : backgroundColor,
        border,
        ...props.style,
      }}
      onClick={props.onClick}>
      <div
        style={{
          display: 'flex',
          height: '56px',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <div
          style={{
            fontFamily: 'Pretendard',
            color: color,
            fontSize: '18px',
            fontWeight: fontWeight,
          }}>
          {props.children}
        </div>
      </div>
    </button>
  );
};

export default BottomRoundButton;
