export enum Roles {
  ADMIN = 'admin',
  SUBADMIN = 'subadmin',
  SALEADMIN = 'saleadmin',
  PARENT = 'parent',
  TUTOR = 'teacher',
  SELLER = 'seller',
}

export enum TutorContractType {
  CONTRACT_TYPE_BUSINESS = 'business',
  CONTRACT_TYPE_FREELANCER = 'freelancer',
  CONTRACT_TYPE_STUDENT = 'student',
}
