import React from 'react';
import AppBottomModal from '../common/AppBottomModal';
import { useAppTermsModal } from '@stores/appTermsModalStore';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { PrivacyContent } from '@pages/landing/PrivacyPage';
import { TermsContent } from '@pages/landing/TermsPage';

export const AppTermsModal: React.FC = () => {
  const store = useAppTermsModal();
  return (
    <AppBottomModal
      height={0}
      open={store.show}
      hideLeftNaviButton
      onRightNaviButtonClick={() => {
        store.close();
      }}>
      {store.show && (
        <OverlayScrollbarsComponent
          defer
          options={{
            scrollbars: { autoHide: 'scroll' },
            overflow: {
              x: 'hidden',
            },
          }}
          style={{
            backgroundColor: '#ffffff',
            marginTop: 0,
            position: 'relative',
            maxHeight: 'calc(100% - 66px)',
            overflow: 'auto',
            width: '100%',
          }}>
          <div style={{ padding: '20px' }}>
            {store.options?.type === 'privacy' && <PrivacyContent />}
            {store.options?.type === 'terms' && <TermsContent />}
          </div>
        </OverlayScrollbarsComponent>
      )}
    </AppBottomModal>
  );
};

export default AppTermsModal;
