import HomeIcon from '@assets/images/app/icon-detail-home.png';
import OfficeIcon from '@assets/images/app/icon-detail-office.png';
import OnlineIcon from '@assets/images/app/icon-detail-online.png';
import ExperienceIcon from '@assets/images/app/icon-detail-experience.png';

export enum LegacyProductStatus {
  ALL = 'all',
  OPEN = 'open',
  CLOSE = 'close',
  HIDE = 'hide',
  DENIED = 'denied',
  OPEN_REQUEST = 'open_request',
  EDIT = 'edit',
}
export enum ProductStatus {
  OPEN = 'open',
  CLOSE = 'close',
}
export enum ProductApproveStatus {
  ACCEPTED = 'accepted',
  REQUESTED = 'requested',
  DENIED = 'denied',
  NOT_READY = 'not_ready',
}

export enum ProductActivityType {
  HOME = 'home',
  STUDIO = 'studio',
  EXPERIENTIAL = 'experiential',
  ONLINE = 'online',
  DIGITAL = 'digital',
  DELIVERY = 'delivery',
  TICKET = 'ticket',
}

export const PRODUCT_CATEGORIES = [
  {
    categoryId: 'physical',
    categoryName: '신체',
    categorySubList: ['맨몸', '교구', '구기', '발레', '댄스'],
  },
  {
    categoryId: 'artistic',
    categoryName: '예술',
    categorySubList: ['음악', '미술', '퍼포먼스'],
  },
  {
    categoryId: 'scientific',
    categoryName: 'STEAM',
    categorySubList: ['과학', '코딩', '엔지니어링', '브레인', '수학'],
  },
  {
    categoryId: 'lang',
    categoryName: '언어',
    categorySubList: ['한글', '영어', '중국어', '기타 언어'],
  },
  {
    categoryId: 'special',
    categoryName: '특별',
    categorySubList: ['스피치', '요리', '자연', '심리', '성교육', '기타 활동'],
  },
];

export const DEFAULT_CLASS_TEMRS = `수업 예정일 2일 전 17시까지 취소 요청시 전액 환불
환불 가능 기간 경과 후에는 환불 불가
환불 가능 기간 중에는 수업일 변경 요청 가능
환불 가능 기간 경과 후 수업일 연장 요청 1회 가능
수업일 변경 이후로는 환불 불가
정기 다회차 클래스 예약시, 수강 횟수가 1/2를 넘어가면 개인사유로 인한 환불 불가`;

export const REVIEW_AVAILABLE_FROM = '2024-06-01';

export const PRODUCT_ACTIVITY_TYPE_TEXT: { [key: string]: { title: string; description: string; icon: string } } = {
  [ProductActivityType.STUDIO]: {
    title: '스튜디오',
    description: '튜터님이 운영하는 공간으로 방문하는 수업입니다.',
    icon: OfficeIcon,
  },
  [ProductActivityType.EXPERIENTIAL]: { title: '체험수업', description: '', icon: ExperienceIcon },
  [ProductActivityType.HOME]: {
    title: '가정방문',
    description: '튜터님이 집으로 오시는 방문수업입니다.',
    icon: HomeIcon,
  },
  [ProductActivityType.ONLINE]: {
    title: '비대면',
    description: '튜터님과 협의한 시간에 실시간 비대면으로 진행되는 수업입니다.',
    icon: OnlineIcon,
  },
  [ProductActivityType.DIGITAL]: {
    title: '디지털상품',
    description: 'PDF 등의 디지털 파일을 구매하는 상품입니다.',
    icon: OnlineIcon,
  },
  [ProductActivityType.TICKET]: { title: '티켓상품', description: '티켓 상품입니다.', icon: OnlineIcon },
  [ProductActivityType.DELIVERY]: {
    title: '배송상품',
    description: '기입하신 주소지로 배송되는 상품입니다.',
    icon: OnlineIcon,
  },
};
