import React from 'react';

export const ChevronDownIcon: React.FC<{ color?: string; style?: any }> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
      <g id="Icon/Outline/chevron-down">
        <path
          id="Icon"
          d="M19 9L12 16L5 9"
          stroke={props.color || '#424242'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const ChevronUpIcon: React.FC<{ color?: string; style?: any }> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
      <g id="Icon/Outline/chevron-up">
        <path
          id="Icon"
          d="M5 15L12 8L19 15"
          stroke={props.color || '#424242'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const NavBackIcon: React.FC<{ color?: string; style?: any }> = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
      <g id="Icon/Outline/arrow-left-white">
        <path
          id="Icon"
          d="M11.6667 22.1673L3.5 14.0007M3.5 14.0007L11.6667 5.83398M3.5 14.0007L24.5 14.0007"
          stroke={props.color || 'white'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const HomeIcon: React.FC<{ color?: string; style?: any }> = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
      <g id="Icon/Outline/home-white">
        <path
          d="M2.625 13.9996L13.0713 3.55209C13.5847 3.03993 14.4153 3.03993 14.9275 3.55209L25.375 13.9996M5.25 11.3746V23.1871C5.25 23.9116 5.838 24.4996 6.5625 24.4996H11.375V18.8121C11.375 18.0876 11.963 17.4996 12.6875 17.4996H15.3125C16.037 17.4996 16.625 18.0876 16.625 18.8121V24.4996H21.4375C22.162 24.4996 22.75 23.9116 22.75 23.1871V11.3746M9.625 24.4996H19.25"
          stroke={props.color || 'white'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
