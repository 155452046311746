import React from 'react';

export const CellTitle: React.FC<{ children?: any }> = (props) => {
  return (
    <div
      style={{
        color: '#242424',
        fontFamily: 'Pretendard',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '24px',
      }}>
      {props.children}
    </div>
  );
};

export default CellTitle;
