import moment from 'moment';
import { v4 } from 'uuid';

export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REGEX_PHONE_NUMBER = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

export const stripEmoji = (string: string): string => {
  const regex =
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
  return string.replace(regex, '');
};

export const validateEmail = (email?: string) => {
  if (!email) {
    return false;
  }
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const feeFormatter = (value: number | string) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export interface TruncateOption {
  lineHeight?: number;
  width?: number;
}

export const truncateStringByLine = (text: string, line: number, options?: TruncateOption): string => {
  const textPartsByLineBreak = text.split('\n').slice(0, line);
  const slicedText = textPartsByLineBreak.join('\n');

  const div = document.createElement('div');
  div.style.cssText = `width: 50vw; height: 70px; padding: 12px; font-size: 14px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; word-break: break-word;`;

  div.innerHTML = slicedText;
  document.body.appendChild(div);
  const clientHeight = div.clientHeight;
  const lineHeight = (options && options.lineHeight) || 20;

  try {
    document.body.removeChild(div);
  } catch (e) {
    console.error(e);
  }

  if (clientHeight <= lineHeight * line) {
    if (slicedText !== text) {
      return slicedText + '...';
    }
    return slicedText;
  }

  const LENGTH_BY_LINE_K = 70;
  const expectedTextLength = LENGTH_BY_LINE_K * line;

  if (expectedTextLength < slicedText.length) {
    const truncatedText = slicedText.substring(0, Math.round(expectedTextLength));
    return truncatedText + '...';
  }
  return slicedText;
};

export const extractFileName = (fileNameString: string) => {
  if (!fileNameString) {
    return {};
  }

  const fileNames = fileNameString.split('.');
  const ext = fileNames.pop();
  const fileName = fileNames.join('.');
  return { fileName: fileName, fileExt: ext };
};

export const getUserDeviceId = () => {
  try {
    const savedDeviceId = localStorage.getItem('igogo-device-id');

    if (savedDeviceId) {
      return savedDeviceId;
    }
    // eslint-disable-next-line no-empty
  } catch {}

  const userDeviceId = getUniqueKeyFromUUID();
  try {
    localStorage.setItem('igogo-device-id', userDeviceId);
    // eslint-disable-next-line no-empty
  } catch {}
  return userDeviceId;
};

export const getLastLoginType = () => {
  try {
    const loginType = localStorage.getItem('last-login-type');

    return loginType;
    // eslint-disable-next-line no-empty
  } catch {}

  return undefined;
};

export const setLastLoginType = (loginType: string) => {
  try {
    localStorage.setItem('last-login-type', loginType);
    // eslint-disable-next-line no-empty
  } catch {}
  return loginType;
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const setAfterLoginRedirectUrl = (url?: string) => {
  try {
    if (url) {
      localStorage.setItem('after-login-url', url);
    } else {
      localStorage.removeItem('after-login-url');
    }
    // eslint-disable-next-line no-empty
  } catch {}
};

export const getAfterLoginRedirectUrl = () => {
  try {
    return localStorage.getItem('after-login-url');

    // eslint-disable-next-line no-empty
  } catch {}

  return undefined;
};

export const getUniqueKeyFromUUID = () => {
  return v4().replace(/-/g, '');
};

export const ageTextFromBirthday = (birthday: string) => {
  let ageText = birthday !== '' ? birthday.replace(/-/g, '') : '';

  if (ageText) {
    ageText = `만 ${getCurrentAge(ageText)}세`;
  }
  return `${ageText} / ${moment(birthday, 'YYYYMMDD').format('YYYY.MM.DD')}`;
};

const compactNumberFormatter = new Intl.NumberFormat('ko', {
  notation: 'compact',
});

export const compactNumber = (number: number): string => {
  return compactNumberFormatter.format(number);
};

export const setHideSellerNoticePopup = () => {
  const today = moment().format('YYYY-MM-DD');
  try {
    localStorage.setItem('igogo-seller-notice-popup-hide-at', today);
    // eslint-disable-next-line no-empty
  } catch {}
};

export const showSellerNoticePopup = () => {
  try {
    const homeBannerHideAt = localStorage.getItem('igogo-seller-notice-popup-hide-at');
    if (homeBannerHideAt) {
      const diff = moment(homeBannerHideAt).add(1, 'day').diff(moment());
      if (diff < 0) {
        return true;
      } else {
        return false;
      }
    }
    // eslint-disable-next-line no-empty
  } catch {}
  return true;
};

export const setHideHomeBanner = () => {
  const today = moment().format('YYYY-MM-DD');
  try {
    localStorage.setItem('igogo-home-banner-hide-at', today);
    // eslint-disable-next-line no-empty
  } catch {}
};

export const showHomeBanner = () => {
  try {
    localStorage.removeItem('disableDayIgogoMarketingBanner');
    // eslint-disable-next-line no-empty
  } catch {}

  try {
    const homeBannerHideAt = localStorage.getItem('igogo-home-banner-hide-at');
    if (homeBannerHideAt) {
      const diff = moment(homeBannerHideAt).add(1, 'day').diff(moment());
      if (diff < 0) {
        return true;
      } else {
        return false;
      }
    }
    // eslint-disable-next-line no-empty
  } catch {}
  return true;
};

export const getCurrentAge = (birthday: string) => {
  if (!birthday || birthday.length !== 8) {
    return '';
  }

  const birthDate = moment(birthday, 'YYYYMMDD').toDate();
  const birthYear = birthDate.getFullYear();
  const birthMonth = birthDate.getMonth();
  const birthDay = birthDate.getDate();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  let age = currentYear - birthYear;

  if (currentMonth < birthMonth) {
    age--;
  } else if (currentMonth === birthMonth && currentDay < birthDay) {
    age--;
  }
  return `${age}`;
};

export const setHideOpenInAppModal = () => {
  const today = moment().format('YYYY-MM-DD');
  try {
    localStorage.setItem('continue-on-web-hide-at', today);
    // eslint-disable-next-line no-empty
  } catch {}
};

export const getShowOpenInAppModal = () => {
  try {
    const openInAppModalHideAt = localStorage.getItem('continue-on-web-hide-at');
    if (openInAppModalHideAt) {
      const diff = moment(openInAppModalHideAt).add(1, 'day').diff(moment());
      if (diff < 0) {
        return true;
      } else {
        return false;
      }
    }
    // eslint-disable-next-line no-empty
  } catch {}
  return true;
};
