import { AppSocket } from '@hooks/appSocket';
import { create } from 'zustand';

interface AppSocketStore {
  connected?: boolean;
  initialized?: boolean;
  appSocket?: AppSocket;
  setConnected: (connected: boolean) => void;
  setInitialized: (initialized: boolean) => void;
  setAppSocket: (appSocket: AppSocket) => void;
}

export const useAppSocketStore = create<AppSocketStore>((set) => ({
  connected: false,
  initialized: false,
  appSocket: undefined,
  setConnected: (connected: boolean) => {
    set({ connected });
  },
  setInitialized: (initialized: boolean) => {
    set({ initialized });
  },
  setAppSocket: (appSocket: AppSocket) => {
    set({ appSocket });
  },
}));
