import { create } from 'zustand';

interface AppTermsModalOption {
  type?: 'terms' | 'privacy';
}
interface AppTermsModal {
  show?: boolean;
  options?: AppTermsModalOption;
  open: (options?: AppTermsModalOption) => void;
  close: () => boolean;
}

export const useAppTermsModal = create<AppTermsModal>((set, get) => ({
  show: false,
  open: (options?: AppTermsModalOption) => {
    set({ show: true, options });
  },
  close: () => {
    const isCloseExecute = !!get().show;
    set({ show: false });
    return isCloseExecute;
  },
}));
