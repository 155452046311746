import { ClassInvoice } from '@models/invoice';
import { PaymentStatement } from '@models/purchase';
import { ClassReportItem } from '@models/report';
import { apiClient } from '.';

export const getClassReport = async (classProductId: string, classInvoiceId: string) => {
  return apiClient.get<ClassReportItem>(`/app/v1/classes/${classProductId}/invoices/${classInvoiceId}/report`);
};

export const getClassInvoice = async (classId: string, invoiceId: string) => {
  return apiClient.get<ClassInvoice>(`/app/v1/classes/${classId}/invoices/${invoiceId}`);
};

export const getPaymentStatement = async (classId: string, invoiceId: string) => {
  return apiClient.get<PaymentStatement>(`/app/v1/classes/${classId}/invoices/${invoiceId}/payment-statement`);
};
