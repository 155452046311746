import { DeeplinkAction } from '@hooks/deeplink';
import { create } from 'zustand';

interface NotificationInfo {
  title?: string;
  description?: string;
  action?: DeeplinkAction;
}
interface NotificationStore {
  notifications: NotificationInfo[];
  addNotification: (notification: NotificationInfo) => void;
  clearNotifications: () => void;
}

export const useNotificationStore = create<NotificationStore>((set) => ({
  notifications: [],
  addNotification: (notification: NotificationInfo) => {
    set((state) => {
      return { notifications: [...state.notifications, notification] };
    });
  },
  clearNotifications: () => {
    set({ notifications: [] });
  },
}));
