import { PagenatedResponse } from '@models/common';
import {
  CreateReviewBody,
  PaymentStatementReview,
  ProductModel,
  ProductSearchQuery,
  UpdateReviewBody,
} from '@models/product';
import { apiClient } from '.';

export const searchProducts = async (query?: ProductSearchQuery) => {
  return apiClient.get<PagenatedResponse<ProductModel>>(`/app/v1/products`, {
    params: query,
  });
};

export const getProduct = async (productId: string) => {
  return apiClient.get<ProductModel>(`/app/v1/products/${productId}`);
};

export const getWeeklyFavoritedProducts = async () => {
  return apiClient.get<ProductModel[]>(`/app/v1/products/favorited`);
};

export const getRecommendProducts = async (productId: string) => {
  return apiClient.get<ProductModel[]>(`/app/v1/products/${productId}/recommends`);
};

export const createMyFavorites = async (data: { productId: string; saleType: string }) => {
  const { productId, saleType } = data;
  return apiClient.post(`/app/v1/products/${productId}/favorite`, { saleType });
};

export const deleteMyFavorites = async (productId: string) => {
  return apiClient.delete(`/app/v1/products/${productId}/favorite`);
};

export const getReview = async (reviewId: string) => {
  return apiClient.get<PaymentStatementReview>(`/app/v1/reviews/${reviewId}`);
};

export const getProductRievews = async (productId: string) => {
  return apiClient.get(`/app/v1/products/${productId}/reviews`);
};

export const createReview = async (body: CreateReviewBody) => {
  return apiClient.post<PaymentStatementReview>(`/app/v1/payment-statement/${body.paymentStatementId}/reviews`, body);
};

export const updateReview = async (body: UpdateReviewBody) => {
  return apiClient.put<PaymentStatementReview>(
    `/app/v1/payment-statement/${body.paymentStatementId}/reviews/${body.reviewId}`,
    body,
  );
};
