import { create } from 'zustand';
import { User } from '../models/auth';
import { persist } from 'zustand/middleware';

export enum LoginStatus {
  LOGIN = 'login',
  ANONYMOUS = 'anonymous',
  READY = 'ready',
  CHECKING = 'checking',
}

interface AuthStore {
  status: LoginStatus;
  user?: User | null;
  setUser: (user?: User) => void;
  favorites?: string[];
  setFavorites: (favorites?: string[]) => void;
}

export const useAuthStore = create(
  persist<AuthStore>(
    (set) => ({
      status: LoginStatus.READY,
      user: null,
      setUser: (user?: User) => {
        set({ user: user });
        set((state) => {
          const newStatus = user ? LoginStatus.LOGIN : LoginStatus.ANONYMOUS;

          if (newStatus !== state.status) {
            return { status: newStatus };
          }
          return state;
        });
      },
      favorites: [],
      setFavorites: (favorites?: string[]) => {
        set({ favorites });
      },
    }),
    {
      name: 'igogo-app-user',
    },
  ),
);
