import { create } from 'zustand';

interface Size {
  width: number;
  height: number;
}
interface LayoutSizeStore {
  layoutSize: Size;
  setLayoutSize: (size: Size) => void;
}

export const useLayoutSizeStore = create<LayoutSizeStore>((set) => ({
  layoutSize: { width: 0, height: 0 },
  setLayoutSize: (size: Size) => {
    set({ layoutSize: size });
  },
}));
