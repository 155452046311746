import { PaymentMethodType } from '@type/portone';

export interface PaymentMethod {
  type: PaymentMethodType;
  label: string;
  paymentGateway: string;
}

export const PAYMENT_METHODS: PaymentMethod[] = [
  {
    type: 'card',
    label: '신용/체크카드',
    paymentGateway: 'nice',
  },
  {
    type: 'kakaopay',
    label: '카카오페이',
    paymentGateway: 'nice',
  },
  {
    type: 'naverpay',
    label: '네이버페이',
    paymentGateway: 'naverpay',
  },
];

export const PAYMENT_METHODS_DICT: { [key: string]: PaymentMethod } = {
  card: {
    type: 'card',
    label: '신용/체크카드',
    paymentGateway: 'nice',
  },
  kakaopay: {
    type: 'kakaopay',
    label: '카카오페이',
    paymentGateway: 'nice',
  },
  naverpay: {
    type: 'naverpay',
    label: '네이버페이',
    paymentGateway: 'naverpay',
  },
};

export enum TossPaymentsPaymentMethodEN {
  CARD = 'CARD',
  EASY_PAY = 'EASY_PAY',
  TRANSFER = 'TRANSFER',
  VIRTUAL_ACCOUNT = 'VIRTUAL_ACCOUNT',
}

export enum TossPaymentsPaymentMethodKR {
  CARD = '카드',
  EASY_PAY = '간편결제',
  TRANSFER = '계좌이체',
  VIRTUAL_ACCOUNT = '가상계좌',
}

export const TOSS_PAYMENTS_CARD_COMPANY_CODE: { [key: string]: string } = {
  '3K': '기업비씨',
  '46': '광주',
  '71': '롯데',
  '30': '산업',
  '31': '비씨',
  '51': '삼성',
  '38': '새마을',
  '41': '신한',
  '62': '신협',
  '36': '씨티',
  '33': '우리',
  W1: '우리',
  '37': '우체국',
  '39': '저축',
  '35': '전북',
  '42': '제주',
  '15': '카카오뱅크',
  '3A': '케이뱅크',
  '24': '토스뱅크',
  '21': '하나',
  '61': '현대',
  '11': '국민',
  '91': '농협',
  '34': '수협',
};
