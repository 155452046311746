import BottomRoundButton from '@components/common/BottomRoundButton';
import { ClassInvoice } from '@models/invoice';
import { ClassSchedule } from '@models/schedule';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useState } from 'react';
import ClassDateEdit from '../../class-invoice/ClassDateEdit';

export interface ClassInvoiceChangeScheduleProps {
  classInvoice: ClassInvoice;
  onConfirm?: (schedules: ClassSchedule[]) => void;
}

export const ClassInvoiceChangeSchedule: React.FC<ClassInvoiceChangeScheduleProps> = (props) => {
  const [schedules, setSchedules] = useState<ClassSchedule[]>([]);
  const classInvoice = props.classInvoice;
  const selectedChildrenList = classInvoice.students || [];
  const otherChildrenCount = classInvoice.additionalParticipantCount || 0;

  let chidrenText = `${selectedChildrenList
    .map((child) => {
      return child.name;
    })
    .join(', ')}`;

  if (otherChildrenCount > 0) {
    chidrenText = `${selectedChildrenList
      .map((child) => {
        return child.name;
      })
      .join(', ')} 외 ${otherChildrenCount}명`;
  }

  useEffect(() => {
    if (classInvoice.schedules) {
      setSchedules(classInvoice.schedules);
    }
  }, [classInvoice]);

  return (
    <OverlayScrollbarsComponent
      defer
      options={{
        scrollbars: { autoHide: 'scroll' },
        overflow: {
          x: 'hidden',
        },
      }}
      style={{
        backgroundColor: '#ffffff',
        marginTop: 0,
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        width: '100%',
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100px',
          borderBottom: '1px solid #EFF1F4',
          backgroundColor: 'white',
          padding: '0 20px',
        }}>
        <div
          style={{
            marginLeft: '16px',
            fontFamily: 'Pretendard',
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '22px',
          }}>
          {chidrenText}
        </div>
      </div>
      <ClassDateEdit
        schedules={classInvoice.schedules}
        onChangeSchedule={(schedules) => {
          setSchedules(schedules);
        }}
      />
      <div style={{ padding: '20px' }}>
        <BottomRoundButton
          style={{ marginTop: '48px' }}
          onClick={() => {
            props.onConfirm && props.onConfirm(schedules);
          }}>
          완료
        </BottomRoundButton>
      </div>
    </OverlayScrollbarsComponent>
  );
};
