import { PaymentStatementReview, ProductModel, ProductSearchQuery } from '@models/product';
import { apiClient } from '.';
import { ClassSchedule } from '@models/schedule';
import { ClassInvoice, CreateClassInvoiceData } from '@models/invoice';
import { ClassReport, ClassReportBody } from '@models/report';
import { PagenatedResponse } from '@models/common';

export const updateProductState = async (productId: string) => {
  return apiClient.patch<ProductModel>(`/tutor/v1/products/${productId}/state`, { params: { productId } });
};

export const updateProduct = async (formData: FormData) => {
  const productId = formData.get('productId');
  return apiClient.put<{ editStep: string; class: ProductModel }>(`/tutor/v1/products/${productId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const createProduct = async (formData: FormData) => {
  return apiClient.post<ProductModel>(`/tutor/v1/products`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getProduct = async (productId: string) => {
  return apiClient.get<ProductModel>(`/tutor/v1/products/${productId}`, { params: { productId } });
};

export const getProductSummary = async (productId: string) => {
  return apiClient.get<{ product: ProductModel; summary: any; reviews?: PaymentStatementReview[] }>(
    `/tutor/v1/products/${productId}/summary`,
    {
      params: { productId },
    },
  );
};

export const searchProducts = async (query?: ProductSearchQuery) => {
  return apiClient.get<PagenatedResponse<ProductModel>>(`/tutor/v1/products`, {
    params: query,
  });
};

export const createReport = async (reportData: ClassReportBody) => {
  const { classProductId, classInvoiceId } = reportData;
  return apiClient.post<ClassReport>(
    `/tutor/v1/products/${classProductId}/invoices/${classInvoiceId}/report`,
    reportData,
  );
};

export const updateReport = async (reportData: ClassReportBody) => {
  const { classProductId, classInvoiceId } = reportData;
  return apiClient.put<ClassReport>(
    `/tutor/v1/products/${classProductId}/invoices/${classInvoiceId}/report`,
    reportData,
  );
};

export const createClassInvoice = async (body: CreateClassInvoiceData) => {
  return apiClient.post<ClassInvoice>(`/tutor/v1/products/${body.classId}/invoice`, body);
};

export const updateClassSchedule = async (data: Partial<ClassSchedule>) => {
  const { classId, requestId, _id } = data;
  return apiClient.put(`/tutor/v1/products/${classId}/invoices/${requestId}/schedules/${_id}`, data);
};

export const updateClassSchedules = async (data: {
  classId: string;
  requestId: string;
  schedules: Partial<ClassSchedule>[];
}) => {
  const { classId, requestId, schedules } = data;
  return apiClient.put(`/tutor/v1/products/${classId}/invoices/${requestId}/schedules`, schedules);
};
