import { PaymentStatement } from '@models/purchase';
import { create } from 'zustand';

export interface ReviewModalData {
  reviewId?: string;
  paymentStatement: PaymentStatement;
  onReviewChanged?: () => void;
}

interface ReviewModalStore {
  show?: boolean;
  data?: ReviewModalData;
  close: () => boolean;
  open: (data?: ReviewModalData) => void;
}

export const useReviewModalStore = create<ReviewModalStore>((set, get) => ({
  show: false,
  close: () => {
    const isCloseExecute = !!get().show;
    set({ show: false });
    return isCloseExecute;
  },
  open: (data?: ReviewModalData) => {
    set({ data: data, show: true });
  },
}));
